import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAction, useActivate } from 'core/store/store';

import { userMenuOrderSelector } from 'state/userMenu/selector';
import { makeUserMenuOrderChangeStatusAction, UserMenuActionType } from 'state/userMenu/actions';
import { isUIEnabledSelector } from 'state/app/selectors';

import { OrderCard } from 'components/OrderCard/OrderCard';
import { OrderCustomerPhone } from '../Orders/OrderCustomerPhone';

import { UserRole } from 'types';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
    },
    card: {
        flex: 1,
    },
});

export const MenuOrderCard = () => {
    useActivate(UserMenuActionType.ORDER_ACTIVATE, UserMenuActionType.ORDER_DEACTIVATE);
    const action = useAction();
    const order = useSelector(userMenuOrderSelector);
    const phoneNumber = (order as any)?.phoneNumber?.value;
    const classes = useStyles();
    const isUIEnabled = useSelector(isUIEnabledSelector);
    return (
        <div className={classes.container}>
            <div className={classes.card}>
                <OrderCard
                    order={order}
                    userRole={UserRole.CUSTOMER}
                    isPending={!isUIEnabled}
                    onIntent={intent => {
                        action(makeUserMenuOrderChangeStatusAction, order.orderId, intent);
                    }}
                />
            </div>
            <Box m={2}>
                {phoneNumber && <OrderCustomerPhone userRole={UserRole.VENDOR} phoneNumber={phoneNumber} />}
            </Box>
        </div>
    );
};
