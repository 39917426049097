import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as StarIcon } from '../../assets/icons/web-24/star--24.svg';
import { Icon } from '../Icon/Icon';
import { Text, TextTypography } from '../Text/Text';

export interface AddressListItemProps {
    name?: string;
    address?: string;
    isDefault?: boolean;
    onClick?: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        cursor: 'pointer',
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        backgroundColor: theme.palette.brandPrimary.lightest,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.light,
        },
    },
    icon: {
        height: 16,
        width: 16,
        borderRadius: 12,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export const AddressListItem = (props: AddressListItemProps) => {
    const { address, name, isDefault, onClick } = props;
    const classes = useStyles();
    return (
        <div className={classes.container} onClick={onClick}>
            <div>
                <Text typography={TextTypography.PARA_ACCENT}>{name}</Text>
                <Text typography={TextTypography.BODY}>{address}</Text>
            </div>
            <div className={classes.icon} onClick={onClick}>
                {isDefault && <Icon color={'#FFA310'} component={StarIcon} svgSize={24} size={16} />}
            </div>
        </div>
    );
};
