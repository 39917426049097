import React from 'react';
import i18n from '../../../i18n';
import { WrappedFieldProps } from 'redux-form';

import { TextControl } from '../TextControl/TextControl';
import { MapControlWrapper } from './MapControlWrapper';

export interface MapControlProps {
    buttonText: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    onCoordinatesChange: (coordinates: { lat: number; lon: number }) => void;
    // Optional:
    initialValue?: string;
    initialCoordinates?: {
        lat: number;
        lon: number;
    };
    error?: string;
    isDisabled?: boolean;
    initWithGeolocationData?: boolean;
    headerText?: string;
}

export const MapControl = (props: MapControlProps) => {
    const { error, ...rest } = props;
    return (
        <MapControlWrapper {...rest}>
            <TextControl
                label={props.label}
                isReadOnly={true}
                error={props.error}
                value={props.value}
                isDisabled={props.isDisabled}
            />
        </MapControlWrapper>
    );
};

export interface MapControlFieldOwnProps {
    buttonText: string;
    label: string;
    onCoordinatesChange: (coordinates: { lat: number; lon: number }) => void;
    // Optional:
    initialValue?: string;
    initialCoordinates?: {
        lat: number;
        lon: number;
    };
    error?: string;
    isDisabled?: boolean;
    initWithGeolocationData?: boolean;
    headerText?: string;
}

export type MapControlFieldProps = MapControlFieldOwnProps & WrappedFieldProps;

export const MapControlField = (props: MapControlFieldProps) => {
    const { t } = i18n.useTranslation();
    const {
        input,
        label,
        isDisabled,
        initWithGeolocationData,
        initialCoordinates,
        initialValue,
        buttonText,
        headerText,
        meta: { touched, invalid, error },
        onCoordinatesChange,
    } = props;
    return (
        <MapControl
            buttonText={buttonText}
            headerText={headerText}
            label={label}
            isDisabled={isDisabled}
            initialCoordinates={initialCoordinates}
            initialValue={initialValue}
            initWithGeolocationData={initWithGeolocationData}
            error={touched && invalid ? t(error) : undefined}
            onCoordinatesChange={onCoordinatesChange}
            {...input}
        />
    );
};
