import React from 'react';
import i18n from '../../../i18n';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { useAction } from 'core/store/store';

import { AuthStatus } from 'state/auth/reducer';
import { authAdminRoleSelector, authRoleSelector, authStatusSelector, authStoreIdSelector } from 'state/auth/selectors';
import { UserMenuSelectedItem } from 'state/userMenu/reducer';
import { makeUserMenuHideAction, makeUserMenuNavigateAction } from 'state/userMenu/actions';

import { Icon } from 'components/Icon/Icon';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { MenuItem, MenuItemProps } from 'components/MenuItem/MenuItem';
import { Text, TextTypography } from 'components/Text/Text';

import { RoutePath } from 'RoutePath';

import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';
import { ReactComponent as UserIcon } from 'assets/icons/web-16/user-web--16.svg';
import { ReactComponent as OrdersIcon } from 'assets/icons/main-16/manage-rules--16.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/main-16/shut-down--16.svg';
import { ReactComponent as LogoIcon } from 'assets/logo-icon.svg';
import { ReactComponent as UserLoginIcon } from 'assets/icons/main-24/user-circle--24.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/main-16/store--16.svg';
import { ReactComponent as AnalysisIcon } from 'assets/icons/main-16/analysis-o--16.svg';

import { UserRole } from 'types';
import { loginAs, logout } from 'core/auth/auth';
import { Hidden } from '@material-ui/core';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import { FooterMenu } from '../../components/Footer/FooterMenu';
import dynamic from 'next/dynamic';
import { SelectLanguageControl } from 'components/SelectLanguageControl/SelectLanguageControl';
import { SelectRegionControl } from '../../components/SelectRegionControl/SelectRegionControl';

const AddToHomeScreen = dynamic<any>(
    () => import('components/AddToHomeScreen/AddToHomeScreen').then(mod => mod.AddToHomeScreen),
    {
        ssr: false,
    },
);

export interface UserMenuNavigationProps {
    onClose: () => void;
    isSsr?: boolean;
    hasHeader?: boolean;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'auto',
    },
    actions: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    mainHeader: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: 16,
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    promoButton: {
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    items: {
        flex: 4,
    },
    loginContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        margin: '0 auto',
    },
    loginWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px',
    },
    loginIcon: {
        marginBottom: 26,
    },
    loginDescription: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    register: {
        width: '100%',
        marginTop: theme.spacing(1.5),
    },
    whatsApp: {
        padding: 0,
    },
    footer: {
        // width: '100%',
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // alignContent: 'center',
        // [theme.breakpoints.down('sm')]: {
        //     paddingBottom: 0,
        // },
    },
    footerElement: {
        flexBasis: '50%',
        height: theme.spacing(9),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        '&:nth-child(-n+3)': {
            borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        },
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(7),
        },
    },
    aboutUs: {
        cursor: 'pointer',
        flexBasis: '100%',
        height: theme.spacing(9),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(7),
        },
    },
    socials: {
        borderLeft: `1px solid ${theme.palette.brandPrimary.accent}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
    },
    documents: {
        height: 37,
        borderLeft: `1px solid ${theme.palette.brandPrimary.accent}`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    socialIcon: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    closeButton: {
        cursor: 'pointer',
    },
    loginAsButton: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    settings: {
        display: 'flex',
        gap: 8,
    },
}));

export const UserMenuNavigation = (props: UserMenuNavigationProps) => {
    const { onClose, hasHeader = true, isSsr = false } = props;
    const { t } = i18n.useTranslation();
    const history = useHistory();
    const isLoggedIn = AuthStatus.LOGGED_IN === useSelector(authStatusSelector);
    const adminRole = useSelector(authAdminRoleSelector);
    const isAdminRoleAvailable = adminRole == UserRole.ADMINISTRATOR || adminRole == UserRole.OPERATION_ADMIN;
    const hasStore = useSelector(authStoreIdSelector) != null;
    const currentRole = useSelector(authRoleSelector);
    const loginAsRole = currentRole === UserRole.CUSTOMER ? UserRole.VENDOR : UserRole.CUSTOMER;
    const action = useAction();
    const menuItems: MenuItemProps[] = [
        {
            title: t('common:userMenu.profile.title'),
            iconComponent: UserIcon,
            onClick: () => action(makeUserMenuNavigateAction, UserMenuSelectedItem.PROFILE),
        },
        // {
        //     title: t('common:userMenu:addresses.title'),
        //     iconComponent: AddressIcon,
        //     onClick: () => action(makeUserMenuNavigateAction, UserMenuSelectedItem.ADDRESSES),
        //     visibleForRole: UserRole.CUSTOMER,
        // },
        {
            title: t('common:userMenu.orders.title'),
            iconComponent: OrdersIcon,
            onClick: () => action(makeUserMenuNavigateAction, UserMenuSelectedItem.ORDERS),
            visibleForRole: UserRole.CUSTOMER,
        },
        {
            title: t('common:userMenu.createStore.title'),
            iconComponent: StoreIcon,
            onClick: () => {
                window.location.assign(RoutePath.REGISTRATION_FAKE);
            },
            visibleForRole: isAdminRoleAvailable ? UserRole.CUSTOMER : UserRole.ADMINISTRATOR,
        },
        {
            title: 'Консоль администратора',
            iconComponent: AnalysisIcon,
            onClick: () => {
                window.location.assign(RoutePath.ADMIN_REPORT);
            },
            visibleForRole: isAdminRoleAvailable ? UserRole.CUSTOMER : UserRole.ADMINISTRATOR,
        },
        // {
        //     title: t('common:userMenu.checkout'),
        //     iconComponent: CheckoutIcon,
        //     onClick: () => {
        //         onClose();
        //     },
        //     visibleForRole: UserRole.CUSTOMER,
        // },
        {
            title: t('common:userMenu.exit'),
            iconComponent: ExitIcon,
            onClick: () => {
                logout(RoutePath.FEED);
                onClose();
            },
        },
    ];
    const classes = useStyles();

    return (
        <div className={classes.container} itemScope itemType="http://schema.org/SiteNavigationElement">
            <div className={classes.actions}>
                <div className={classes.mainHeader}>
                    <LogoIcon />
                    <div className={classes.settings}>
                        <SelectRegionControl />
                        <SelectLanguageControl />
                    </div>
                    {hasHeader && (
                        <div className={classes.closeButton} onClick={onClose}>
                            <Icon component={CloseIcon} size={16} svgSize={16} />
                        </div>
                    )}
                    {/*{currentRole !== UserRole.CUSTOMER && (*/}
                    {/*    <div className={classes.closeButton} onClick={onClose}>*/}
                    {/*        <Icon component={CloseIcon} size={16} svgSize={16} />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
                {/*{!hasStore && (*/}
                {/*    <div className={classes.promoButton}>*/}
                {/*        <PromoButton*/}
                {/*            onClick={() => {*/}
                {/*                if (isLoggedIn) {*/}
                {/*                    loginAs(UserRole.VENDOR);*/}
                {/*                } else {*/}
                {/*                    if (!isSsr) {*/}
                {/*                        history.push(RoutePath.REGISTRATION_STORE);*/}
                {/*                    } else {*/}
                {/*                        window.location.assign(RoutePath.REGISTRATION_STORE);*/}
                {/*                    }*/}
                {/*                }*/}
                {/*                action(makeUserMenuHideAction);*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}
                {hasStore && (
                    <div className={classes.loginAsButton}>
                        <LargeButton
                            onClick={() => {
                                loginAs(loginAsRole);
                                onClose();
                            }}
                        >
                            {loginAsRole === UserRole.CUSTOMER
                                ? t('common:userMenu:loginAsCustomer')
                                : t('common:userMenu:loginAsVendor')}
                        </LargeButton>
                    </div>
                )}

                <AddToHomeScreen />

                {isLoggedIn ? (
                    <div className={classes.items}>
                        {menuItems
                            .filter(item => !item.visibleForRole || item.visibleForRole === currentRole)
                            .map((item: MenuItemProps) => (
                                <MenuItem
                                    key={item.title}
                                    iconComponent={item.iconComponent}
                                    title={item.title}
                                    onClick={item.onClick}
                                />
                            ))}
                    </div>
                ) : (
                    <div className={classes.loginContainer}>
                        <div className={classes.loginWrapper}>
                            <Hidden xsDown>
                                <Icon className={classes.loginIcon} component={UserLoginIcon} size={64} svgSize={64} />
                                <Text typography={TextTypography.LEAD_ACCENT}>{t('common:userMenu:introduce')}</Text>
                            </Hidden>
                            <Text typography={TextTypography.PARA} className={classes.loginDescription}>
                                {t('common:userMenu:title')}
                            </Text>
                            <LargeButton
                                color={LargeButtonColor.SUCCESS}
                                onClick={() => {
                                    if (!isSsr) {
                                        history.push(RoutePath.LOGIN + '?backUrl=' + window.location.pathname);
                                    } else {
                                        window.location.assign(
                                            RoutePath.LOGIN + '?backUrl=' + window.location.pathname,
                                        );
                                    }
                                    action(makeUserMenuHideAction);
                                }}
                            >
                                {t('common:userMenu:haveLogin')}
                            </LargeButton>
                            <div className={classes.register}>
                                <LargeButton
                                    color={LargeButtonColor.TRANSPARENT}
                                    onClick={() => {
                                        if (!isSsr) {
                                            history.push(RoutePath.SIGNUP);
                                        } else {
                                            window.location.assign(RoutePath.SIGNUP);
                                        }
                                        action(makeUserMenuHideAction);
                                    }}
                                >
                                    {t('common:userMenu:registration')}
                                </LargeButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <div className={classes.footer}>
                    <FooterMenu />
                </div>
            </div>
            <CustomerMenu navigationPath={CustomerMenuSelectedItem.MENU} />
        </div>
    );
};
