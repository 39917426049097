import React from 'react';

import { useAction, useActionDispatchOnMount } from 'core/store/store';

import { makeUserMenuAddressFetchAction, makeUserMenuAddressSubmitAction } from 'state/userMenu/actions';
import { AddressForm } from 'forms/AddressForm/AddressForm';

export const MenuAddressCard = () => {
    const action = useAction();
    useActionDispatchOnMount(true, makeUserMenuAddressFetchAction);
    return <AddressForm onSubmit={data => action(makeUserMenuAddressSubmitAction, data)} />;
};
