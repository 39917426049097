import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        background: 'linear-gradient(12.04deg, rgba(218, 37, 77, 0.2) 0%, rgba(255, 155, 107, 0.2) 100%)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'center',
    },
}));

export const FailurePage: FC = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.container}>{children}</div>;
};
