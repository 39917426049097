import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { formatISODateTime } from 'core/date/date';

import { OrderListResponseItem } from 'state/order/types';

import { Address } from '../Address/Address';
import { Dot } from '../Dot/Dot';
import { Icon } from '../Icon/Icon';
import { Currency } from '../Currency/Currency';
import { T } from '../T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { Text, TextTypography } from '../Text/Text';

import { getOrderStatusIcon } from './utils';
import { Rating } from './Rating';
import { OrderStatus } from '../../types';
import { useSelector } from 'react-redux';
import { authIsoCodeSelector } from '../../state/auth/selectors';

export interface OrderListItemProps {
    order: OrderListResponseItem;
    showRating: boolean;
    onClick: () => void;
    onRate: (orderId: string, rating: number) => void;
}

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.brandPrimary.lightest,
        borderRadius: theme.spacing(2.5),
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        '&:hover': {
            boxShadow: `0 10px 25px ${theme.palette.fixedPrimary.lightest}, 0 0 0 4px ${theme.palette.fixedPrimary.lightest}`,
        },
    },
    header: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    status: {
        flex: 1,
    },
    price: {
        display: 'flex',
    },
    footer: {
        alignItems: 'center',
        display: 'flex',
    },
    orderDate: {
        flex: 1,
    },
}));

export const OrderListItem = ({ order, showRating, onClick, onRate }: OrderListItemProps) => {
    const classes = useStyles();

    const {
        orderId,
        orderDelivery,
        orderNumber,
        orderStatus,
        orderCreationDateTime,
        totalPrice,
        storeName,
        orderRating,
        storeAddress,
        isoCode,
    } = order;
    const StatusIcon = getOrderStatusIcon(orderStatus);

    const handleRating = useCallback((rating: number) => onRate(orderId, rating), [orderId, onRate]);

    return (
        <Box className={classes.card} p={2} onClick={onClick}>
            <div className={classes.header}>
                <div className={classes.icon}>{StatusIcon && <Icon component={StatusIcon} svgSize={16} />}</div>
                <div className={classes.status}>
                    <Text typography={TextTypography.LEAD_ACCENT}>
                        <T enum={TranslatableEnum.ORDER_STATUS}>{orderStatus}</T>
                    </Text>
                </div>
                {orderNumber && (
                    <div>
                        <Text typography={TextTypography.BODY}>ID {orderNumber}</Text>
                    </div>
                )}
            </div>
            <Box pl={4}>
                <Address>{orderDelivery?.data?.address}</Address>
                <div className={classes.price}>
                    {totalPrice && (
                        <Text typography={TextTypography.BODY}>
                            <Currency isoCode={isoCode ? isoCode : storeAddress?.isoCode}>{totalPrice}</Currency>
                        </Text>
                    )}
                    {totalPrice && orderDelivery?.deliveryType && <Dot />}
                    {orderDelivery?.deliveryType && (
                        <Text typography={TextTypography.BODY}>
                            <T enum={TranslatableEnum.DELIVERY_TYPE}>{orderDelivery?.deliveryType}</T>
                        </Text>
                    )}
                </div>
                {storeName && (
                    <div>
                        <Text typography={TextTypography.BODY}>{storeName}</Text>
                    </div>
                )}
                <footer className={classes.footer}>
                    <div className={classes.orderDate}>
                        {orderCreationDateTime && (
                            <Text typography={TextTypography.BODY}>{formatISODateTime(orderCreationDateTime)}</Text>
                        )}
                    </div>

                    {showRating && orderStatus === OrderStatus.CLOSED && (
                        <Rating value={orderRating?.value} onChange={handleRating} />
                    )}
                </footer>
            </Box>
        </Box>
    );
};
