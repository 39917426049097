import React from 'react';
import i18n from '../../../i18n';

import { MapControlAutocomplete } from './MapControlAutocomplete';

import { Endpoint } from '../../Endpoint';

export interface MapControlCityViewProps {
    value: string;
    onSelect: (value: string, isStreetAddress: boolean) => void;
}

export const MapControlCityView = (props: MapControlCityViewProps) => {
    const { t } = i18n.useTranslation();
    return (
        <MapControlAutocomplete
            label={t('common:addressControl.city')}
            value={props.value}
            // onClose={}
            onSelect={props.onSelect}
            location={{ lat: 0, lon: 0 }}
            radius={50e6}
            endpoint={Endpoint.CITY_AUTOCOMPLETE}
        />
    );
};
