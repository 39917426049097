import React, { ReactNode } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Text, TextTypography } from '../Text/Text';

export type Row = Record<string, string>;

export enum DataTableCellAlignment {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export interface DataTableColumn<T extends Row> {
    // Optional:
    align?: DataTableCellAlignment;
    accent?: boolean;
    key?: keyof T;
    label?: ReactNode;
    renderer?: (value: any, row?: T) => ReactNode;
    setMinWidth?: boolean;
}

export interface DataTableProps<T extends Row> {
    columns: DataTableColumn<T>[];
    data: T[];
    // Optional:
    onClick?: (row: T) => void;
}

const useStyles = makeStyles(theme => ({
    tableHeader: {
        backgroundColor: theme.palette.brandPrimary.lightest,
    },
    tableCell: {
        borderBottomWidth: 0,
        padding: theme.spacing(1.5),
    },
    tableCellHead: {
        borderBottomWidth: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

const useRowStyles = makeStyles(theme => ({
    root: {
        verticalAlign: 'baseline',
    },
}));

export const DataTable = <T extends {}>(props: DataTableProps<T>) => {
    const classes = useStyles();
    const rowClasses = useRowStyles();
    return (
        <Table>
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {props.columns.map((c, i) => (
                        <TableCell
                            align={c.align}
                            key={i}
                            className={classes.tableCellHead}
                            size={c.setMinWidth ? 'small' : 'medium'}
                        >
                            <Text typography={TextTypography.BODY_ACCENT}>{c.label}</Text>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(props.data || []).map((row, i) => (
                    <TableRow key={i} classes={rowClasses} onClick={() => (props.onClick ? props.onClick(row) : null)}>
                        {props.columns.map((c, i) => (
                            <TableCell align={c.align} key={i} className={classes.tableCell}>
                                <Text typography={c.accent ? TextTypography.BODY_ACCENT : TextTypography.BODY}>
                                    {c.renderer ? c.renderer(row[c.key], row) : row[c.key]}
                                </Text>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
