import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { useAction } from 'core/store/store';

import { UserMenuSelectedItem } from 'state/userMenu/reducer';
import { userMenuRoutingSelectedItemSelector } from 'state/userMenu/selector';
import { makeUserMenuNavigateAction } from 'state/userMenu/actions';

import { FullScreenDialog } from 'components/FullScreenDialog/FullScreenDialog';
import { Icon } from 'components/Icon/Icon';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Text, TextTypography } from 'components/Text/Text';
import { UserMenuNavigation } from './UserMenuNavigation';
import { UserMenuHeader } from './UserMenuHeader';
import { UserMenuContents } from './UserMenuContents';

import { ReactComponent as BackIcon } from 'assets/icons/main-24/back-o--24.svg';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';

export interface UserMenuDialogProps {
    onClose: () => void;
    isSsr?: boolean;
}

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        padding: theme.spacing(2.5),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    closeButton: {
        cursor: 'pointer',
    },
    title: {
        paddingRight: theme.spacing(3),
        width: '100%',
        textAlign: 'center',
    },
    profile: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contents: {
        height: 'calc(100vh - 69px)',
        overflow: 'auto',
        flex: 1,
    },
}));

const navMenuOrderMarkers = [UserMenuSelectedItem.ORDERS, UserMenuSelectedItem.ORDER];

export const UserMenuMobile = (props: UserMenuDialogProps) => {
    const { onClose, isSsr } = props;
    const userMenuRoutingSelectedItem = useSelector(userMenuRoutingSelectedItemSelector);
    const action = useAction();
    const classes = useStyles();

    if (userMenuRoutingSelectedItem === UserMenuSelectedItem.MENU) {
        return <UserMenuNavigation isSsr={isSsr} onClose={onClose} />;
    }
    const navigationPath =
        userMenuRoutingSelectedItem === UserMenuSelectedItem.ORDER
            ? UserMenuSelectedItem.ORDERS
            : userMenuRoutingSelectedItem === UserMenuSelectedItem.ADDRESS
            ? UserMenuSelectedItem.ADDRESSES
            : UserMenuSelectedItem.MENU;

    const customerMenuNavigationPath = navMenuOrderMarkers.includes(userMenuRoutingSelectedItem)
        ? CustomerMenuSelectedItem.ORDERS
        : CustomerMenuSelectedItem.MENU;

    return (
        <ErrorBoundary>
            <div className={classes.profile}>
                <div className={classes.header}>
                    {userMenuRoutingSelectedItem !== UserMenuSelectedItem.ORDERS && (
                        <div
                            className={classes.closeButton}
                            onClick={() => action(makeUserMenuNavigateAction, navigationPath)}
                        >
                            <Icon component={BackIcon} size={24} svgSize={24} />
                        </div>
                    )}
                    <div className={classes.title}>
                        <Text typography={TextTypography.LEAD_ACCENT}>
                            <UserMenuHeader />
                        </Text>
                    </div>
                </div>
                <div className={classes.contents}>
                    <UserMenuContents />
                </div>
                <CustomerMenu navigationPath={customerMenuNavigationPath} />
            </div>
        </ErrorBoundary>
    );
};

export const UserMenuMobileDialog = (props: UserMenuDialogProps) => {
    const { onClose, isSsr } = props;
    return (
        <FullScreenDialog onClose={onClose}>
            {({ onClose }) => <UserMenuMobile isSsr={isSsr} onClose={onClose} />}
        </FullScreenDialog>
    );
};
