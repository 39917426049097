import { captureException } from '@sentry/nextjs';
import React from 'react';
import { ensureScriptIsAdded } from 'core/hooks/useScript';
import { useMounted } from 'core/hooks/useMounted';

declare const window: {
    YandexCheckout: any;
    location: {
        origin: string;
    };
};

const PAYMENT_FORM_ID = 'payment-form';

export interface PaymentFormProps {
    confirmationToken: string;
    returnUrl: string;
}

const makeYandexCheckout = (confirmationToken: string, returnUrl: string) => {
    return new window.YandexCheckout({
        confirmation_token: confirmationToken,
        return_url: window.location.origin + returnUrl,
        error_callback(error: unknown) {
            captureException(error);
        },
        customization: {
            colors: {
                background: '#ffffff',
                border: '#eeeeee',
                controlPrimary: '#0f1825',
                controlPrimaryContent: '#ffffff',
            },
        },
    });
};

/**
 * We shouldn't really need it, this is just in case
 */
const ensureYandexCheckoutIsAvailable = async () => {
    const attemptCount = 20;
    const delayMs = 100;

    for (let i = 0; i < attemptCount; i++) {
        if (window.YandexCheckout) {
            return;
        }
        await new Promise(resolve => setTimeout(resolve, delayMs));
    }
};

export const PaymentForm = (props: PaymentFormProps) => {
    useMounted(() => {
        const callback = async () => {
            await ensureScriptIsAdded('https://yookassa.ru/checkout-widget/v1/checkout-widget.js');
            await ensureYandexCheckoutIsAvailable();
            const yandexCheckout = makeYandexCheckout(props.confirmationToken, props.returnUrl);
            yandexCheckout.render(PAYMENT_FORM_ID);
        };

        callback().catch(captureException);
    });

    return <div id={PAYMENT_FORM_ID} />;
};
