import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../i18n';
import { Link } from 'react-router-dom';

import { makeRoute } from 'core/router/router';

import { Currency } from '../Currency/Currency';
import { DateTime } from '../DateTime/DateTime';
import { DataTable, DataTableCellAlignment } from '../DataTable/DataTable';
import { Icon } from '../Icon/Icon';
import { T } from '../T/T';
import { Text, TextTypography } from '../Text/Text';
import { OrdersTableSummary } from './OrdersTableSummary';

import { RoutePath } from 'RoutePath';

import { ReactComponent as EditIcon } from 'assets/icons/main-16/edit-o--16.svg';
import { OrderResponse, OrderResponseProductOption } from 'state/order/types';
import { TranslatableEnum } from '../T/GetTranslationForEnum';

export interface OrderDetailsProps {
    orderResponse: OrderResponse;
    // Optional:
    showEditIcon?: boolean;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    contents: {
        flex: 1,
    },
    info: {
        display: 'flex',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    infoDate: {
        color: theme.palette.fixedPrimary.lighter,
        flex: 1,
    },
    infoOrderId: {
        color: theme.palette.fixedPrimary.lighter,
    },
}));

export const OrdersTable: FC<OrderDetailsProps> = props => {
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    const order = props.orderResponse;
    return (
        <div className={classes.container}>
            <div className={classes.info}>
                <Text className={classes.infoDate} typography={TextTypography.BODY}>
                    <DateTime>{order && order.orderStatusHistory[0] && order.orderStatusHistory[0].timestamp}</DateTime>
                </Text>
                <Text className={classes.infoOrderId} typography={TextTypography.BODY}>
                    ID {order && order.orderNumber}
                </Text>
            </div>
            <div className={classes.contents}>
                <DataTable
                    columns={[
                        {
                            key: 'name',
                            label: t('orders:columns.name'),
                            renderer: (name: string, row) => (
                                <>
                                    <Text typography={TextTypography.BODY_ACCENT}>{name}</Text>
                                    {row.orderedOptions.map(
                                        (option: Partial<OrderResponseProductOption>, index: number) => (
                                            <div key={index}>
                                                <Text typography={TextTypography.BODY}>{option.name}</Text>
                                            </div>
                                        ),
                                    )}
                                </>
                            ),
                        },
                        {
                            key: 'quantity',
                            label: '',
                            setMinWidth: true,
                            align: DataTableCellAlignment.RIGHT,
                            renderer: v => `${v}×`,
                        },
                        {
                            key: 'unitQuantity',
                            label: '',
                            setMinWidth: true,
                            renderer: v => v,
                        },
                        {
                            key: 'unitType',
                            label: '',
                            setMinWidth: true,
                            renderer: v => <T enum={TranslatableEnum.UNIT_TYPE}>{v}</T>,
                        },
                        {
                            key: 'totalPrice',
                            label:
                                props.showEditIcon && props.orderResponse ? (
                                    <Link
                                        to={makeRoute(RoutePath.ORDERS_NEW_DIALOG_EDIT, {
                                            orderId: props.orderResponse.orderId,
                                        })}
                                    >
                                        <Icon component={EditIcon} size={16} svgSize={16} />
                                    </Link>
                                ) : null,
                            setMinWidth: true,
                            align: DataTableCellAlignment.RIGHT,
                            renderer: v => (
                                <Currency isoCode={order.isoCode} bold={true}>
                                    {v}
                                </Currency>
                            ),
                        },
                    ]}
                    data={order && order.orderedProducts}
                />
            </div>
            {order && <OrdersTableSummary orderResponse={order} />}
        </div>
    );
};
