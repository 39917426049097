import React, { FC } from 'react';

import { Button, ButtonColor } from '../../Button/Button';

import { OrderChangeStatusIntent } from 'types';

export interface PreparedOrderActionsProps {
    onIntent: (intent: OrderChangeStatusIntent) => void;
    isPending: boolean;
}

export const PreparedOrderActions: FC<PreparedOrderActionsProps> = ({ onIntent, isPending }) => {
    return (
        <div>
            <Button
                disabled={isPending}
                color={ButtonColor.SUCCESS}
                onClick={() => {
                    onIntent(OrderChangeStatusIntent.FINISH_PREPARING);
                }}
            >
                OK
            </Button>
        </div>
    );
};
