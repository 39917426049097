import React, { Fragment, ReactNode, useCallback, useState } from 'react';

import { MapControlDialog } from './MapControlDialog';
import { useMap } from './useMap';

export interface MapControlWrapperProps {
    buttonText: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    onCoordinatesChange: (coordinates: { lat: number; lon: number }, city: string, streetAddress: string) => void;
    // Optional:
    children?: ReactNode;
    isDisabled?: boolean;
    initWithGeolocationData?: boolean;
    headerText?: string;
    forceOpen?: boolean;
    forbidClosingWithEmptyAddress?: boolean;
    initialCoordinates?: {
        lat: number;
        lon: number;
    };
}

export const MapControlWrapper = (props: MapControlWrapperProps) => {
    const { initWithGeolocationData, onChange, onCoordinatesChange, forceOpen, initialCoordinates, value } = props;
    const {
        city,
        streetAddress,
        coordinates,
        isGeolocationPending,
        onSetCity,
        onSetStreetAddress,
        onCenterChange,
        onOk,
        initLocation,
    } = useMap({
        onAddressChange: onChange,
        onCoordinatesChange,
        initialAddress: value,
        initialCoordinates: initialCoordinates ?? { lat: null, lon: null },
    });
    const [showDialog, setShowDialog] = useState(forceOpen);
    const handleSetStreetAddress = useCallback(
        (value, skipSetCoordinates) => {
            return onSetStreetAddress(value, skipSetCoordinates);
        },
        [onSetStreetAddress],
    );
    return (
        <Fragment>
            <div
                onClick={() => {
                    if (!props.isDisabled || isGeolocationPending) {
                        setShowDialog(true);
                        if (initWithGeolocationData && !streetAddress) {
                            initLocation();
                        }
                    }
                }}
            >
                {props.children}
            </div>
            {showDialog && (
                <MapControlDialog
                    isGeolocationPending={isGeolocationPending}
                    buttonText={props.buttonText}
                    headerText={props.headerText}
                    lat={coordinates.lat}
                    lon={coordinates.lon}
                    city={city}
                    streetAddress={streetAddress}
                    setCity={onSetCity}
                    setStreetAddress={handleSetStreetAddress}
                    onClose={() => setShowDialog(false)}
                    onCenterChanged={onCenterChange}
                    onOk={() => {
                        onOk();
                        setShowDialog(false);
                    }}
                    hideCloseButton={forceOpen}
                    forbidClosingWithEmptyAddress={props.forbidClosingWithEmptyAddress}
                />
            )}
        </Fragment>
    );
};
