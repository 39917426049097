import React from 'react';

import { FullScreenDialog } from 'components/FullScreenDialog/FullScreenDialog';

import { MapControlMapView } from './MapControlMapView';

export interface MapControlDialogProps {
    hideCloseButton: boolean;
    isGeolocationPending: boolean;
    buttonText: string;
    headerText: string;
    lat: number;
    lon: number;
    city: string;
    streetAddress: string;
    forbidClosingWithEmptyAddress: boolean;
    setCity: (value: string) => void;
    setStreetAddress: (value: string, skipSetCoordinates?: boolean) => void;
    onClose: () => void;
    onCenterChanged: (lat: number, lon: number) => void;
    onOk: () => void;
}

export enum MapControlDialogView {
    MAP,
    CITY,
    STREET_ADDRESS,
}

export const MapControlDialog = (props: MapControlDialogProps) => {
    const { hideCloseButton, isGeolocationPending, city, streetAddress, setCity, setStreetAddress, onClose } = props;
    return (
        <FullScreenDialog onClose={onClose}>
            {({ onClose }) => {
                return (
                    <MapControlMapView
                        isGeolocationPending={isGeolocationPending}
                        lat={props.lat}
                        lon={props.lon}
                        city={city}
                        setCity={setCity}
                        streetAddress={streetAddress}
                        setStreetAddress={setStreetAddress}
                        onClose={onClose}
                        onCenterChanged={props.onCenterChanged}
                        onOk={props.onOk}
                        buttonText={props.buttonText}
                        headerText={props.headerText}
                        hideCloseButton={hideCloseButton}
                        forbidClosingWithEmptyAddress={props.forbidClosingWithEmptyAddress}
                    />
                );
            }}
        </FullScreenDialog>
    );
};
