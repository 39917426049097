import React, { FC } from 'react';

import { Button, ButtonColor } from '../../Button/Button';
// import { Icon } from '../../Icon/Icon';

import { OrderChangeStatusIntent } from 'types';

// import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';

export interface DeliveringOrderActionsProps {
    onIntent: (intent: OrderChangeStatusIntent) => void;
    isPending: boolean;
}

export const DeliveringOrderActions: FC<DeliveringOrderActionsProps> = ({ onIntent, isPending }) => {
    return (
        <div>
            {/* temporary disable order declining
            <Button
                disabled={isPending}
                color={ButtonColor.ERROR}
                onClick={() => onIntent(OrderChangeStatusIntent.DECLINE)}
            >
                <Icon component={CloseIcon} size={16} svgSize={16} />
            </Button>{' '}*/}
            <Button
                disabled={isPending}
                color={ButtonColor.SUCCESS}
                onClick={() => onIntent(OrderChangeStatusIntent.FINISH_DELIVERY)}
            >
                OK
            </Button>
        </div>
    );
};
