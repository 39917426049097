import React, { FC, Fragment } from 'react';
import i18n from '../../../i18n';
import { SupportedCountryCodes } from '../../types';

export interface AddressData {
    city: {
        name: string;
    };
    street: {
        name: string;
    };
    house: {
        number: number;
    };
    bulk: {
        number: number;
    };
    building: {
        number: number;
    };
    apartment: {
        number: number;
    };
    // Optional:
    formattedAddress?: string;
    isoCode?: SupportedCountryCodes;
}

const orderedAddressFields: Array<keyof AddressData> = ['city', 'street', 'house', 'bulk', 'building', 'apartment'];

export interface AddressProps {
    children: AddressData;
}

export const Address: FC<AddressProps> = props => {
    const address: AddressData = props.children;
    const { t } = i18n.useTranslation();
    const chunks = address
        ? // @ts-ignore
          orderedAddressFields
              .filter(k => address[k])
              .map(k =>
                  // @ts-ignore
                  t(`common:address.${k}`, address[k]),
              )
        : [];
    return <Fragment>{chunks.join(', ')}</Fragment>;
};

export const getAddressForSSR = (address?: AddressData) => {
    if (!address) {
        return '';
    }

    if (address.formattedAddress) {
        return address.formattedAddress;
    }

    const chunks: string[] = [];

    if (address.city?.name) {
        chunks.push(address.city.name);
    }

    if (address.street?.name) {
        chunks.push(address.street.name);
    }

    if (address.street?.number) {
        chunks.push(address.street.number);
    }

    return chunks.join(' ');
};
