import React, { useCallback, useEffect, useState } from 'react';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useEnterListener } from 'core/form/form';

import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { MobilePhoneControl } from 'components/MobilePhoneControl/MobilePhoneControl';
import { Text, TextTypography } from 'components/Text/Text';

import { ReactComponent as SilentRegistrationSketch } from 'assets/silentRegistrationSketch.svg';
import { AuthHeader } from 'pages/Login/components/AuthHeader';
import { LegalDocumentLink } from 'types';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export interface SilentLoginPhoneFormProps {
    errorMessage: string;
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    onClose: () => void;
    onNext: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    contents: {
        flex: 1,
        width: 408,
        paddingTop: theme.spacing(13),
        [theme.breakpoints.down(480)]: {
            width: '100%',
            padding: theme.spacing(2),
        },
    },
    button: {
        width: '100%',
        paddingTop: theme.spacing(1),
        paddingButton: theme.spacing(3),
    },
    item: {
        width: '100%',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    title: {
        paddingTop: theme.spacing(1),
    },
    phone: {
        width: '100%',
    },
    legalDocs: {
        maxWidth: 600,
        flexGrow: 0,
        flexBasis: 'auto',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    legalLink: {
        color: theme.palette.fixedPrimary.primary,
    },
}));

export const SilentLoginPhoneForm = (props: SilentLoginPhoneFormProps) => {
    const { phoneNumber, setPhoneNumber, onClose, onNext } = props;
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = i18n.useTranslation();
    const classes = useStyles();
    useEffect(() => setErrorMessage(''), [phoneNumber]);
    const submit = useCallback(() => {
        const isPhoneNumberValid = isPossiblePhoneNumber(phoneNumber);
        if (isPhoneNumberValid) {
            onNext();
        } else {
            setErrorMessage(t('common:silentLoginDialog.phoneForm.invalidPhoneNumber'));
        }
    }, [phoneNumber]);
    useEnterListener(submit);
    return (
        <div className={classes.container}>
            <div className={classes.button}>
                <AuthHeader onBack={onClose} />
            </div>
            <div className={classes.contents}>
                <div className={classes.item}>
                    <SilentRegistrationSketch />
                </div>
                <div className={classes.item}>
                    <Text className={classes.title} typography={TextTypography.LEAD_ACCENT}>
                        {t('common:silentLoginDialog.phoneForm.title')}
                    </Text>
                    <Text typography={TextTypography.PARA}>{t('common:silentLoginDialog.phoneForm.text')}</Text>
                </div>
                <div className={classes.phone}>
                    <Box mb={2}>
                        <MobilePhoneControl
                            label={t('common:silentLoginDialog.phoneForm.phoneNumberControl')}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            autoFocus={true}
                        />
                    </Box>
                    {errorMessage && <FormErrorMessage error={errorMessage} />}
                </div>
                <div className={classes.button}>
                    <LargeButton color={LargeButtonColor.SUCCESS} onClick={submit}>
                        {t('common:silentLoginDialog.phoneForm.confirmButton')}
                    </LargeButton>
                </div>
            </div>
            <div className={classes.legalDocs}>
                <Text typography={TextTypography.PARA}>{t('common:terms.termsText1Checkout')}</Text>{' '}
                <Text typography={TextTypography.PARA_ACCENT}>
                    <a
                        className={classes.legalLink}
                        href={LegalDocumentLink.AGREEMENT}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('common:terms.termsAgreementLink')}
                    </a>
                </Text>{' '}
                <Text typography={TextTypography.PARA}>{t('common:terms.termsText2')}</Text>{' '}
                <Text typography={TextTypography.PARA_ACCENT}>
                    <a
                        className={classes.legalLink}
                        href={LegalDocumentLink.PERSONAL_DATA_PROCESSING_CONSENT}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('common:terms.termsPersonalDataProcessingConsentLink')}
                    </a>
                </Text>{' '}
                <Text typography={TextTypography.PARA}>{t('common:terms.termsSms')}</Text>
            </div>
        </div>
    );
};
