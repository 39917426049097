import classNames from 'classnames';
import { CheckoutPromoItemFooter } from './CheckoutPromoItemFooter';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { makeStyles } from '@material-ui/core';
import { PromoDto } from 'types';
import { VendorPromoListItem } from 'pages/Vendor/VendorPromo/components/VendorPromoListItem';
import React from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingLeft: ({ isFullScreen }: CheckoutCartPromosProps) => (isFullScreen ? theme.spacing(2) : 30),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: '100%',
        flex: 1,
    },
    addPromoButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'sticky',
        bottom: theme.spacing(3),
        [theme.breakpoints.down(640)]: {
            bottom: theme.spacing(9),
        },
    },
    addPromoButton: {
        height: 64,
        width: 150,
        '& svg': {
            '& path': {
                fill: '#ffffff',
            },
        },
        '& span': {
            paddingLeft: theme.spacing(1),
        },
    },
    promosContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    promosList: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: theme.spacing(2),
        width: 400,
    },
    fullSizePromoList: {
        [theme.breakpoints.down(390)]: {
            width: 335,
        },
        [theme.breakpoints.between(390, 768)]: {
            width: 390,
        },
        [theme.breakpoints.between(768, 1150)]: {
            width: 768,
        },
        [theme.breakpoints.between(1150, 1550)]: {
            width: 1150,
        },
        [theme.breakpoints.up(1550)]: {
            width: 1533,
        },
    },
    promoListItem: {
        width: 351,
    },
}));

export interface CheckoutCartPromosProps {
    promos: PromoDto[];
    activePromoId?: string;
    isFullScreen: boolean;
    onClose: () => void;
}

export const CheckoutCartPromos = (props: CheckoutCartPromosProps) => {
    const { promos, activePromoId, isFullScreen, onClose } = props;
    const classes = useStyles(props);
    return (
        <ErrorBoundary>
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.promosContainer}>
                        <div className={classNames(classes.promosList, isFullScreen && classes.fullSizePromoList)}>
                            {promos.map(promo => (
                                <VendorPromoListItem
                                    className={classes.promoListItem}
                                    key={promo.promoId}
                                    promo={promo}
                                    customFooter={
                                        <CheckoutPromoItemFooter
                                            activePromoId={activePromoId}
                                            promoId={promo.promoId}
                                            onClose={onClose}
                                        />
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};
