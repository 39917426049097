import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { feedAddressSelector } from 'state/feed/selectors';
import {
    checkoutCartItemsQuantitySelector,
    checkoutCartSelector,
    checkoutCartStorePaymentInfoSelector,
} from 'state/checkout/selectors';

import { useAction, useActionDispatchOnMount } from 'core/store/store';
import { makeCheckoutUpdateCartInfo } from 'state/checkout/actions';
import { EmptyCheckoutCart } from 'pages/Checkout/EmptyCheckoutCart';

import { CheckoutCartAddress } from './CheckoutCartAddress';
import { CheckoutCartDelivery } from './CheckoutCartDelivery';
import { CheckoutCartPayment } from './CheckoutCartPayment';
import { CheckoutCartPromos } from './CheckoutCartPromos';
import { CheckoutCartOrders } from './CheckoutCartOrders';
import { CheckoutFinishCartOrders } from './CheckoutFinishCartOrders';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeSnackbarCreateAction } from 'state/snackbar/actions';
import { SnackbarMessageType } from 'state/snackbar/reducer';
import { ReachedGoal, sendReachGoalMetrics } from '../../core/ym/ym';

export interface CheckoutCartProps {
    isFullScreen?: boolean;
}

export const CheckoutCart = (props: CheckoutCartProps) => {
    const { isFullScreen } = props;

    const theme = useTheme();
    const action = useAction();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const hasItems = useSelector(checkoutCartItemsQuantitySelector);
    const address = useSelector(feedAddressSelector);

    const storePayments = useSelector(checkoutCartStorePaymentInfoSelector);
    const cart = useSelector(checkoutCartSelector);

    const isSeveralAvailableDeliveryMethods = useMemo(() => (cart?.availableDeliveries || []).length > 1, [cart]);
    const isSeveralAvailablePaymentMethods = useMemo(
        () => (storePayments || []).filter(paymentMethod => paymentMethod.enabled).length > 1,
        [storePayments],
    );

    const [isAddressPage, setIsAddressPage] = useState(false);
    const openAddressPage = useCallback(() => setIsAddressPage(true), [setIsAddressPage]);
    const closeAddressPage = useCallback(() => setIsAddressPage(false), [setIsAddressPage]);

    const [isDeliveryPage, setIsDeliveryPage] = useState(false);
    const openDeliveryPage = useCallback(() => {
        if (isSeveralAvailableDeliveryMethods) {
            setIsDeliveryPage(true);
        } else {
            action(makeSnackbarCreateAction, SnackbarMessageType.CHECKOUT_ONE_AVAILABLE_DELIVERY_TYPE, {});
        }
    }, [setIsDeliveryPage, isSeveralAvailableDeliveryMethods]);
    const closeDeliveryPage = useCallback(() => setIsDeliveryPage(false), [setIsDeliveryPage]);

    const [isPaymentPage, setIsPaymentPage] = useState(false);
    const openPaymentPage = useCallback(() => {
        if (isSeveralAvailablePaymentMethods) {
            setIsPaymentPage(true);
        } else {
            action(makeSnackbarCreateAction, SnackbarMessageType.CHECKOUT_ONE_AVAILABLE_PAYMENT_TYPE, {});
        }
    }, [setIsPaymentPage, isSeveralAvailablePaymentMethods]);
    const closePaymentPage = useCallback(() => setIsPaymentPage(false), [setIsPaymentPage]);

    const [isPromosPage, setIsPromosPage] = useState(false);
    const openPromosPage = useCallback(() => setIsPromosPage(true), [setIsPromosPage]);
    const closePromosPage = useCallback(() => setIsPromosPage(false), [setIsPromosPage]);

    const [isPrevOrderPage, setIsPrevOrderPage] = useState(false);
    const openPrevOrderPage = useCallback(() => {
        sendReachGoalMetrics(ReachedGoal.CHECKOUT_SECOND_STEP);
        setIsPrevOrderPage(true);
    }, [setIsPrevOrderPage]);
    const closePrevOrderPage = useCallback(() => setIsPrevOrderPage(false), [setIsPrevOrderPage]);

    useActionDispatchOnMount(true, makeCheckoutUpdateCartInfo);

    if (isAddressPage) {
        return <CheckoutCartAddress isFullScreen={isFullScreen || isMobile} onClose={closeAddressPage} />;
    }

    if (!hasItems) {
        return (
            <EmptyCheckoutCart
                address={address}
                onAddressClick={openAddressPage}
                isFullScreen={isFullScreen || isMobile}
            />
        );
    }

    if (isDeliveryPage) {
        return <CheckoutCartDelivery isFullScreen={isFullScreen || isMobile} onClose={closeDeliveryPage} />;
    }

    if (isPaymentPage) {
        return <CheckoutCartPayment isFullScreen={isFullScreen || isMobile} onClose={closePaymentPage} />;
    }

    if (isPromosPage) {
        return <CheckoutCartPromos isFullScreen={isFullScreen || isMobile} onClose={closePromosPage} />;
    }

    if (isPrevOrderPage) {
        return (
            <CheckoutFinishCartOrders
                isFullScreen={isFullScreen || isMobile}
                onOpenPrevOrderPage={openPrevOrderPage}
                onClose={closePrevOrderPage}
            />
        );
    }

    return (
        <CheckoutCartOrders
            isFullScreen={isFullScreen || isMobile}
            onOpenAddressPage={openAddressPage}
            onOpenDeliveryPage={openDeliveryPage}
            onOpenPaymentPage={openPaymentPage}
            onOpenPromosPage={openPromosPage}
            onOpenPrevOrderPage={openPrevOrderPage}
        />
    );
};
