import React from 'react';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { checkoutCardStorePromosSelector, checkoutCartSelector } from 'state/checkout/selectors';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon/Icon';
import { Text, TextTypography } from 'components/Text/Text';

import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';

import { CheckoutCartPromos as CheckoutPromos } from './CheckoutPromos';

export interface CheckoutCartPromosProps {
    isFullScreen?: boolean;
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    header: {
        height: 87,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    orderTextWrapper: {
        alignText: 'center',
        textAlign: 'center',
        flex: 1,
    },
    mainContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'rgba(15, 24, 37, 0.05)',
    },
    containerScroll: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(3),
    },
    icon: {
        display: 'block',
        fill: theme.palette.brandPrimary.main,
        height: theme.spacing(2),
        width: theme.spacing(2),
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
    logoIcon: {
        cursor: 'pointer',
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    iconContainer: {
        cursor: 'pointer',
        borderRadius: theme.spacing(2),
        height: theme.spacing(4),
        padding: theme.spacing(1),
        width: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    address: {
        height: 139,
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    addressLink: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    addressOpenIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    map: {
        marginRight: theme.spacing(2),
    },
    cartItem: {
        paddingTop: theme.spacing(3),
        '&:first-child': {
            paddingTop: 0,
        },
    },
    checkoutSettings: {
        display: 'flex',
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    checkoutSetting: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
        '&:first-child': {
            borderRight: `1px solid ${theme.palette.brandPrimary.accent}`,
        },
    },
    badge: {
        height: theme.spacing(2),
        width: 40,
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedSuccess.main,
    },
    badgeWithSum: {
        height: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedPrimary.primary,
    },
    checkout: {
        cursor: 'pointer',
        padding: theme.spacing(2),
    },
    paymentText: {
        color: theme.palette.fixedCritical.main,
    },
    paymentChoose: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioElement: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        backgroundColor: '#FBFEF9',
        boxShadow: '0px 0px 50px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        marginBottom: theme.spacing(3),
    },
    deliveryRadio: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addressInfo: {
        height: 'calc(100% - 80px)',
        display: 'flex',
        flexDirection: 'column',
    },
    addressElement: {
        paddingTop: theme.spacing(3),
    },
    addressText: {
        textAlign: 'center',
        display: 'flex',
        minHeight: 72,
    },
    emptyBody: {
        padding: theme.spacing(6),
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    emptyBodyMessage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        paddingBottom: theme.spacing(3),
    },
    successMessage: {
        backgroundColor: theme.palette.fixedSuccess.accent,
        borderWidth: 2,
        borderColor: theme.palette.fixedSuccess.main,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1.25),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        maxWidth: 352,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '0px 10px 20px rgba(36, 49, 67, 0.2)',
    },
    successMessageTitle: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    successButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down(1340)]: {
            flexDirection: 'column',
        },
    },
    buttonElement: {
        color: '#45CA52',
        cursor: 'pointer',
    },
    radioIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioLabel: {
        paddingLeft: theme.spacing(2),
    },
}));

export const CheckoutCartPromos = (props: CheckoutCartPromosProps) => {
    const { isFullScreen, onClose } = props;

    const promos = useSelector(checkoutCardStorePromosSelector);
    const cart = useSelector(checkoutCartSelector);

    const activePromoId = cart?.selectedPromo?.promoApplied ? cart.selectedPromo.promo.promoId : null;

    const classes = useStyles();

    const { t } = i18n.useTranslation();

    return (
        <ErrorBoundary>
            <Box className={classes.mainContainer}>
                <div className={classes.header}>
                    <div className={classes.iconContainer} onClick={onClose}>
                        <Icon component={BackIcon} size={16} svgSize={16} className={classes.icon} />
                    </div>
                    <div className={classes.orderTextWrapper}>
                        <Box textAlign={'center'}>
                            <Text typography={TextTypography.LEAD_STRONG}>{t('feed:checkout.promos.title')}</Text>
                        </Box>
                    </div>
                    <div className={classes.iconContainer} />
                </div>
                <div className={classes.containerScroll}>
                    <CheckoutPromos
                        promos={promos}
                        activePromoId={activePromoId}
                        isFullScreen={isFullScreen}
                        onClose={onClose}
                    />
                </div>
            </Box>
        </ErrorBoundary>
    );
};
