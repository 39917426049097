import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RadioButton } from '../RadioButton/RadioButton';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { Icon } from '../Icon/Icon';

export interface RadioGroupControlProps {
    name: string;
    items: RadioItem[];
    onChange: (value: any) => any;
    // Optional
    defaultValue?: any;
}

export interface RadioItem {
    value: any;
    label: string;
    icon: any;
    enabled: boolean;
    type?: string;
    data?: { hoursToStore: number; comment: string }[];
}

const useStyles = makeStyles(theme => ({
    element: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
        backgroundColor: '#FBFEF9',
        boxShadow: '0px 0px 50px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        marginBottom: theme.spacing(3),
    },
}));

export const RadioGroupControl = (props: RadioGroupControlProps) => {
    const { items, name, defaultValue, onChange } = props;
    const classes = useStyles();

    return (
        <RadioGroup name={name} defaultValue={defaultValue} style={{ width: '100%' }} onChange={onChange}>
            {items.map(item => (
                <div className={classes.element}>
                    <FormControlLabel
                        disabled={!item.enabled}
                        value={item.value}
                        control={<RadioButton />}
                        label={item.label}
                    />
                    <div>
                        <Icon component={item.icon} size={32} svgSize={32} />
                    </div>
                </div>
            ))}
        </RadioGroup>
    );
};
