import React, { FC, Fragment } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../i18n';

import { Address } from '../Address/Address';
import { Currency } from '../Currency/Currency';
import { T } from '../T/T';
import { Text, TextTypography } from '../Text/Text';

import { OrderResponse } from 'state/order/types';

import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
export interface OrdersTableSummaryProps {
    orderResponse: OrderResponse;
    // Optional:
    values?: any;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    contents: {
        flex: 1,
    },
    summaryGroup: {
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    summaryRow: {
        display: 'flex',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    summaryLabel: {
        flexGrow: 0,
        flexBasis: 'auto',
        whiteSpace: 'nowrap',
    },
    summaryValue: {
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'right',
    },
    freeDeliveryChip: {
        backgroundColor: theme.palette.fixedSuccess.accent,
        borderRadius: theme.spacing(0.75),
        height: theme.spacing(2),
        paddingLeft: theme.spacing(0.75),
        paddingRight: theme.spacing(0.75),
    },
    freeDeliveryChipText: {
        color: theme.palette.fixedSuccess.main,
        padding: 0,
    },
}));

export const OrdersTableSummary: FC<OrdersTableSummaryProps> = props => {
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    const data = props.orderResponse;
    const subtotal = props.values
        ? props.orderResponse.orderedProducts.reduce((acc, p) => {
              const k = props.values[p.productId] ?? p.quantity;
              return acc + (k * p.totalPrice) / p.quantity;
          }, 0)
        : data.subtotal;
    const deliveryPrice = data?.deliveryInfo?.data?.deliveryPrice || 0;
    const total = props.values ? deliveryPrice + subtotal : data.total;
    const address = data?.deliveryInfo?.data?.address;

    const specific = data?.deliveryInfo?.deliveryType === 'SPECIFIC_DELIVERY';

    return (
        <Fragment>
            <div className={classes.summaryGroup}>
                <div className={classes.summaryRow}>
                    <div className={classes.summaryLabel}>{t('orders:summary.subtotal')}</div>
                    <div className={classes.summaryValue}>
                        {data && (
                            <Currency isoCode={data.isoCode} bold={true}>
                                {subtotal}
                            </Currency>
                        )}
                    </div>
                </div>
            </div>
            {data && data.appliedPromo && (
                <>
                    <div className={classes.summaryGroup}>
                        <div className={classes.summaryRow}>
                            <div className={classes.summaryLabel}>{t('orders:summary.activePromo.description')}</div>
                            <div className={classes.summaryValue}>{data.appliedPromo.description}</div>
                        </div>
                        {data.appliedPromo.discountAmount > 0 && (
                            <div className={classes.summaryRow}>
                                <div className={classes.summaryLabel}>
                                    {t('orders:summary.activePromo.discountAmount')}
                                </div>
                                <div className={classes.summaryValue}>
                                    <Currency isoCode={data.isoCode} bold={true}>
                                        {data.appliedPromo.discountAmount}
                                    </Currency>{' '}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
            <div className={classes.summaryGroup}>
                <div className={classes.summaryRow}>
                    <div className={classes.summaryLabel}>{t('orders:summary.address')}</div>
                    <div className={classes.summaryValue}>{address && <Address>{address}</Address>}</div>
                </div>
                <div className={classes.summaryRow}>
                    <div className={classes.summaryLabel}>{t('orders:summary.payment')}</div>
                    <div className={classes.summaryValue}>
                        {data && data.paymentInfo && data.paymentInfo.paymentType && (
                            <T enum={TranslatableEnum.PAYMENT_TYPE}>{data.paymentInfo.paymentType}</T>
                        )}
                    </div>
                </div>
                <div className={classes.summaryRow}>
                    <div className={classes.summaryLabel}>
                        {t('orders:summary.delivery')}{' '}
                        {data && !deliveryPrice && !specific && (
                            <Chip
                                className={classes.freeDeliveryChip}
                                label={
                                    <Text className={classes.freeDeliveryChipText} typography={TextTypography.NOTE}>
                                        FREE
                                    </Text>
                                }
                            />
                        )}
                    </div>
                    <div className={classes.summaryValue}>
                        {data && (
                            <Fragment>
                                <T enum={TranslatableEnum.DELIVERY_TYPE}>{data.deliveryInfo.deliveryType}</T>{' '}
                                {!specific && (
                                    <Currency isoCode={data.isoCode} bold={true}>
                                        {deliveryPrice}
                                    </Currency>
                                )}
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.summaryGroup}>
                <div className={classes.summaryRow}>
                    <div className={classes.summaryLabel}>{t('orders:summary.total')}</div>
                    <div className={classes.summaryValue}>
                        {data && (
                            <Currency isoCode={data.isoCode} bold={true}>
                                {total}
                            </Currency>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
