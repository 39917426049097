import React, { useEffect, useState } from 'react';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CodeControl } from 'components/CodeControl/CodeControl';
import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { Text, TextTypography } from 'components/Text/Text';
import { AuthHeader } from 'pages/Login/components/AuthHeader';
import { Icon } from 'components/Icon/Icon';
import { ReactComponent as SyncIcon } from 'assets/icons/status/sync--16.svg';
import { ReactComponent as ResetPasswordIcon } from 'assets/glyphs/password.svg';

export interface SilentLoginOtpFormProps {
    errorMessage: string;
    isDisabled: boolean;
    phoneNumber: string;
    timer: number;
    onBack: () => void;
    onNext: (otp: string) => void;
    onResend: () => void;
    setErrorMessage: (errorMessage: string) => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    contents: {},
    item: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        textAlign: 'center',
    },
    footer: {
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        paddingBottom: theme.spacing(3),
    },
    backIcon: {
        height: 32,
        width: 32,
        cursor: 'pointer',
        borderRadius: 16,
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
}));

const ResendTimer = ({ timer }: { timer: number }) => {
    const { t } = i18n.useTranslation();
    const timeLeft = String(timer).padStart(2, '0');
    return <span>{t('common:silentLoginDialog.otpForm.resend', { timeLeft })}</span>;
};

const ResendButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = i18n.useTranslation();
    return (
        <div
            onClick={onClick}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box mr={1}>
                <Icon component={SyncIcon} size={16} svgSize={16} />
            </Box>
            <Text component="div" typography={TextTypography.PARA}>
                {t('common:silentLoginDialog.otpForm.resendButton')}
            </Text>
        </div>
    );
};

export const SilentLoginOtpForm = (props: SilentLoginOtpFormProps) => {
    const { errorMessage, setErrorMessage, isDisabled, phoneNumber, timer, onBack, onNext, onResend } = props;
    const { t } = i18n.useTranslation();
    const [otp, setOtp] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if (errorMessage) {
            setOtp('');
        }
    }, [errorMessage, otp]);

    return (
        <div className={classes.container}>
            <AuthHeader onBack={onBack} />
            <div className={classes.contents}>
                <div className={classes.item}>
                    {/* See: https://3steps.atlassian.net/browse/ANY-2881 */}
                    {/*<div className={classes.backIcon} onClick={() => onBack()}>*/}
                    {/*    <Icon component={BackIcon} size={24} svgSize={24} />*/}
                    {/*</div>*/}
                    <Icon component={ResetPasswordIcon} size={64} svgSize={64} />
                </div>
                <div className={classes.item}>
                    <Text typography={TextTypography.LEAD_ACCENT}>{t('common:silentLoginDialog.otpForm.title')}</Text>
                </div>
                <div className={classes.item}>
                    <Text component="div" typography={TextTypography.PARA}>
                        {t('common:silentLoginDialog.otpForm.descriptionLine1', { phoneNumber })}
                    </Text>
                    <Text component="div" typography={TextTypography.PARA}>
                        {t('common:silentLoginDialog.otpForm.descriptionLine2')}
                    </Text>
                </div>
                <div className={classes.item}>
                    <Box mb={2}>
                        <CodeControl
                            disabled={isDisabled}
                            value={otp}
                            onChange={(value: string) => {
                                setErrorMessage('');
                                setOtp(value);
                                if (value.replace(/\s+/g, '').length === 6) {
                                    onNext(value);
                                }
                            }}
                        />
                    </Box>
                    {errorMessage && <FormErrorMessage error={errorMessage} />}
                </div>
            </div>
            <div className={classes.footer}>
                {!isDisabled && (
                    <div>{timer === 0 ? <ResendButton onClick={onResend} /> : <ResendTimer timer={timer} />}</div>
                )}
            </div>
        </div>
    );
};
