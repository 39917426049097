import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useAction } from 'core/store/store';

import { makeUserMenuHideAction } from 'state/userMenu/actions';
import { userMenuIsShownSelector } from 'state/userMenu/selector';

import { UserMenuDialog } from 'pages/UserMenu/UserMenu';
import { UserMenuMobileDialog } from 'pages/UserMenu/UserMenuMobile';

export const MenuContainer = ({ isSsr }: { isSsr: boolean }) => {
    const showMenuDialog = useSelector(userMenuIsShownSelector);
    const action = useAction();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up(768));

    const handleMenuClose = useCallback(() => {
        action(makeUserMenuHideAction);
    }, [action]);

    if (showMenuDialog) {
        return isLargeScreen ? (
            <UserMenuDialog isSsr={isSsr} onClose={handleMenuClose} />
        ) : (
            <UserMenuMobileDialog isSsr={isSsr} onClose={handleMenuClose} />
        );
    }

    return null;
};
