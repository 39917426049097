import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useEnterListener } from 'core/form/form';

import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { PasswordControl } from 'components/PasswordControl/PasswordControl';
import { Text, TextTypography } from 'components/Text/Text';

import { AuthHeader } from 'pages/Login/components/AuthHeader';
import { ReactComponent as LoginSketch } from 'assets/loginSketch.svg';
import { RoutePath } from 'RoutePath';

export interface SilentLoginPasswordFormProps {
    errorMessage: string;
    onBack: () => void;
    onClose: () => void;
    onNext: (password: string) => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    contents: {
        flex: 1,
        width: 408,
        paddingTop: theme.spacing(13),
        [theme.breakpoints.down(480)]: {
            width: '100%',
            padding: theme.spacing(2),
        },
    },
    button: {
        paddingTop: theme.spacing(1),
    },
    item: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    phone: {},
}));

export const SilentLoginPasswordForm = (props: SilentLoginPasswordFormProps) => {
    const { onBack, onNext } = props;
    const [errorMessage, setErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const { t } = i18n.useTranslation();
    const classes = useStyles();
    useEffect(() => setErrorMessage(''), [password]);
    const submit = useCallback(() => {
        if (password && password.length > 0) {
            onNext(password);
        } else {
            setErrorMessage(t('common:validation.required'));
        }
    }, [password]);

    const resetPassword = useCallback(() => {
        window.location.assign(RoutePath.RESET_PASSWORD);
    }, []);

    useEnterListener(submit);
    return (
        <div className={classes.container}>
            <AuthHeader onBack={onBack} />
            <div className={classes.contents}>
                <div className={classes.item}>
                    <LoginSketch />
                </div>
                <div className={classes.item}>
                    <Text typography={TextTypography.DISPLAY_STRONG}>
                        {t('common:silentLoginDialog.passwordForm.title')}
                    </Text>
                </div>
                <div className={classes.phone}>
                    <Box mb={2}>
                        <PasswordControl
                            label={t('common:silentLoginDialog.passwordForm.passwordControl')}
                            value={password}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setPassword(e.target.value);
                            }}
                            autoFocus={true}
                        />
                    </Box>
                    {errorMessage && <FormErrorMessage error={errorMessage} />}
                </div>
                <div className={classes.button}>
                    <LargeButton color={LargeButtonColor.SUCCESS} onClick={submit}>
                        {t('common:silentLoginDialog.passwordForm.nextButton')}
                    </LargeButton>
                </div>
                <Box mt={1}>
                    <div>
                        <LargeButton onClick={resetPassword} color={LargeButtonColor.TRANSPARENT}>
                            {t('common:silentLoginDialog.passwordForm.forgotPassword')}
                        </LargeButton>
                    </div>
                </Box>
            </div>
        </div>
    );
};
