import React, { useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon/Icon';
import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';
import { Text, TextTypography } from 'components/Text/Text';
import { ReactComponent as EmptyCheckoutIcon } from 'assets/icons/main-24/shopping-cart--24.svg';
import { Box } from '@material-ui/core';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import { useAction } from 'core/store/store';
import i18n from '../../../i18n';
import { useSelector } from 'react-redux';
import { checkoutCheckoutSuccessMessageSelector } from 'state/checkout/selectors';
import { ReactComponent as NextIcon } from 'assets/icons/main-16/chevron-right--16.svg';
import { makeCheckoutCloseCartAction } from 'state/checkout/actions';
import { hasLocalStorageItem, LocalStorageKey } from 'core/storage/storage';
import { CheckoutSuccess } from './CheckoutSuccess';
import css from './EmptyCheckoutCart.module.css';

export interface CheckoutEmptyCartProps {
    onAddressClick: () => void;
    address: string;
    isFullScreen?: boolean;
}

export const EmptyCheckoutCart = (props: CheckoutEmptyCartProps) => {
    const { isFullScreen, onAddressClick, address } = props;
    const action = useAction();
    const { t } = i18n.useTranslation();
    const showSuccessMessageOrderId = useSelector(checkoutCheckoutSuccessMessageSelector);

    const hasAddressChanged = useMemo(() => {
        return hasLocalStorageItem(LocalStorageKey.ADDRESS_CHANGED_BY_USER);
    }, [address]);

    const onBackButtonClick = useCallback(() => {
        action(makeCheckoutCloseCartAction);
    }, [action]);

    return (
        <ErrorBoundary>
            <div className={css.mainContainer}>
                <div className={css.header}>
                    <div className={css.iconContainer} onClick={onBackButtonClick}>
                        {isFullScreen && <Icon component={BackIcon} size={16} svgSize={16} className={css.icon} />}
                    </div>
                    <div className={css.orderTextWrapper}>
                        <Text typography={TextTypography.LEAD_ACCENT}>{t('feed:checkout:myOrder')}</Text>
                    </div>
                    <div className={css.iconContainer} />
                </div>
                <div className={css.addressLink} onClick={onAddressClick}>
                    <div>
                        <div>
                            <Text typography={TextTypography.CAPTION}>{t('feed:checkout:myAddress')}</Text>
                        </div>
                        <div>
                            <Text typography={TextTypography.BODY_ACCENT}>
                                {hasAddressChanged ? address : t('feed:checkout:clarifyAddress')}
                            </Text>
                        </div>
                    </div>
                    <div className={css.addressOpenIcon}>
                        <Icon component={NextIcon} size={16} svgSize={16} className={css.icon} />
                    </div>
                </div>
                <div className={css.containerScroll}>
                    <div className={css.emptyBody}>
                        <div className={css.emptyBodyMessage}>
                            <Icon className={css.emptyIcon} component={EmptyCheckoutIcon} size={64} svgSize={24} />
                            <Box mt={3} mb={1} textAlign={'center'}>
                                <Text typography={TextTypography.LEAD_ACCENT}>{t('feed:checkout:empty')}</Text>
                            </Box>
                            <Box textAlign={'center'}>
                                <Text typography={TextTypography.PARA}>{t('feed:checkout:chooseProducts')}</Text>
                            </Box>
                        </div>
                        {showSuccessMessageOrderId && (
                            <div className={css.successMessage}>
                                <CheckoutSuccess orderId={showSuccessMessageOrderId} />
                            </div>
                        )}
                    </div>
                </div>
                <CustomerMenu navigationPath={CustomerMenuSelectedItem.CHECKOUT} />
            </div>
        </ErrorBoundary>
    );
};
