import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from '../Icon/Icon';

import { ReactComponent as DotIcon } from 'assets/glyphs/dot.svg';

const useStyles = makeStyles(theme => ({
    dot: {
        display: 'inline-block',
        textAlign: 'center',
        width: theme.spacing(3),
    },
}));

interface DotProps {
    color?: string;
}

export const Dot: FC<DotProps> = ({ color = '#0F1825' }) => {
    const classes = useStyles();
    return (
        <span className={classes.dot}>
            <Icon component={DotIcon} size={8} color={color} svgSize={8} />
        </span>
    );
};
