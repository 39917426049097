import { makeStyles } from '@material-ui/core/styles';
import { Box, Hidden } from '@material-ui/core';
import React from 'react';
import i18n from '../../../../i18n';

import { Icon } from 'components/Icon/Icon';
import { Text, TextTypography } from 'components/Text/Text';

import { ReactComponent as LogoVertical } from 'assets/logo-vertical.svg';
import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';

export interface AuthHeaderProps {
    onBack: () => void;
    // Optional:
    onLogoClick?: () => void;
}

const useStyles = makeStyles(theme => ({
    backDesktop: {
        position: 'absolute',
        top: 24,
        left: 40,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.fixedPrimary.primary,
        cursor: 'pointer',
        width: 100,
        [theme.breakpoints.down(500)]: {
            top: 32,
            left: 32,
        },
    },
    backDesktopIcon: {
        marginRight: 8,
        fill: theme.palette.brandPrimary.main,
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
}));

export const AuthHeader = (props: AuthHeaderProps) => {
    const { onBack, onLogoClick } = props;
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    return (
        <div>
            <div className={classes.backDesktop} onClick={onBack}>
                <Icon className={classes.backDesktopIcon} component={BackIcon} size={16} svgSize={16} />
                <Hidden xsDown>
                    <Text typography={TextTypography.BODY_ACCENT}>
                        {t('common:silentLoginDialog.passwordForm.back')}
                    </Text>
                </Hidden>
            </div>
            <Box m={2} textAlign="center" onClick={onLogoClick}>
                <LogoVertical />
            </Box>
        </div>
    );
};
