import React, { useCallback } from 'react';
import i18n from '../../../i18n';

import { useSilentRegistration } from 'core/silentRegistration/silentRegistration';

import { FullScreenDialog } from 'components/FullScreenDialog/FullScreenDialog';

import { UserRole } from 'types';

import { SilentLoginOtpForm } from './SilentLoginOtpForm';
import { SilentLoginPhoneForm } from './SilentLoginPhoneForm';
import { SilentLoginPasswordForm } from './SilentLoginPasswordForm';

export interface SilentLoginDialogProps {
    onClose: () => void;
    onSuccess: (jwt: string, username: string) => void;
}

enum PhoneVerificationStage {
    PHONE,
    OTP,
    PASSWORD,
}

export const SilentLoginDialog = (props: SilentLoginDialogProps) => {
    const {
        stage,
        setStage,
        errorMessage,
        phoneNumber,
        setPhoneNumber,
        generateOtp,
        isPending,
        timer,
        verifyOtp,
        setErrorMessage,
        login,
        shouldTryAgainLater,
    } = useSilentRegistration(UserRole.CUSTOMER, props.onSuccess);

    const { t } = i18n.useTranslation();

    const error = errorMessage ?? (shouldTryAgainLater && t('common:phoneVerificationControl.tryAgainLater'));

    const handleBackClick = useCallback(() => {
        setStage(PhoneVerificationStage.PHONE);
    }, [setStage]);

    return (
        <FullScreenDialog onClose={props.onClose}>
            {({ onClose }) => {
                switch (stage) {
                    case PhoneVerificationStage.PHONE:
                        return (
                            <SilentLoginPhoneForm
                                errorMessage={error}
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                                onClose={onClose}
                                onNext={() => generateOtp(phoneNumber)}
                            />
                        );
                    case PhoneVerificationStage.OTP:
                        return (
                            <SilentLoginOtpForm
                                errorMessage={error}
                                isDisabled={isPending}
                                phoneNumber={phoneNumber}
                                timer={timer}
                                onBack={handleBackClick}
                                onNext={otp => verifyOtp(phoneNumber, otp)}
                                onResend={() => generateOtp(phoneNumber)}
                                setErrorMessage={setErrorMessage}
                            />
                        );
                    case PhoneVerificationStage.PASSWORD:
                        return (
                            <SilentLoginPasswordForm
                                errorMessage={error}
                                onClose={onClose}
                                onBack={handleBackClick}
                                onNext={password => login(phoneNumber, password)}
                            />
                        );
                }
            }}
        </FullScreenDialog>
    );
};
