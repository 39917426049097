import { captureException } from '@sentry/nextjs';
import { useCallback, useEffect, useState } from 'react';

import { appConfig } from 'config/app';

import { postRequest } from 'core/axios/axios';
import { getLocalStorageItem, LocalStorageKey } from 'core/storage/storage';
import { getErrorForFieldOrGeneric } from 'core/form/form';
import { makeUrl } from 'core/utils/utils';

import { Endpoint } from 'Endpoint';

import { UserRole } from 'types';
import { useAction } from 'core/store/store';
import { makeSnackbarErrorAction } from 'state/snackbar/actions';

export enum PhoneVerificationStage {
    PHONE,
    OTP,
    PASSWORD,
}

export const useSilentRegistration = (
    role: UserRole,
    onSuccess?: (jwt: string, phoneNumber: string, userAlreadyExists?: boolean) => void,
    correlationId?: string,
) => {
    const action = useAction();
    const [stage, setStage] = useState(PhoneVerificationStage.PHONE);
    const savedPhone: string = getLocalStorageItem(LocalStorageKey.PHONE);
    const [phoneNumber, setPhoneNumber] = useState(savedPhone);
    const [userAlreadyExists, setUserAlreadyExists] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isPending, setIsPending] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldTryAgainLater, setShouldTryAgainLater] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (timer > 0) {
            timeout = setTimeout(() => setTimer(timer - 1), 1000);
        }
        return () => clearTimeout(timeout);
    }, [timer]);

    const generateOtp = useCallback(async (phoneNumber: string, captcha?: string) => {
        if (timer > 0) {
            setShouldTryAgainLater(true);
            return;
        } else {
            setShouldTryAgainLater(false);
        }
        try {
            setIsPending(true);
            const url = makeUrl(Endpoint.SILENT_SIGNUP_OTP, { role });
            await postRequest(url, { username: phoneNumber, captcha });
            if (role === UserRole.VENDOR) {
                setStage(PhoneVerificationStage.OTP);
            } else {
                // ANY-2874
                const urlLogin = makeUrl(
                    role === UserRole.CUSTOMER ? '/api/users/customer' : '/api/users/vendor',
                    {},
                    { correlationId },
                );

                const { headers } = await postRequest(urlLogin, { username: phoneNumber, otp: '' });
                setTimer(0);
                if (onSuccess) {
                    onSuccess(headers.authorization, phoneNumber, userAlreadyExists);
                }
            }
        } catch (e) {
            if (e.isAxiosError && e.response.status === 409) {
                setStage(PhoneVerificationStage.PASSWORD);
                setUserAlreadyExists(true);
                return 'userAlreadyExists';
            } else {
                captureException(e);
                const error = getErrorForFieldOrGeneric(e, 'phoneNumber');
                setErrorMessage(error);
            }
        } finally {
            setTimer(appConfig.otpTimeout);
            setIsPending(false);
        }
    }, []);

    const verifyOtp = useCallback(
        async (phoneNumber: string, otp: string) => {
            try {
                setIsPending(true);
                const url = makeUrl(
                    role === UserRole.CUSTOMER ? '/api/users/customer' : '/api/users/vendor',
                    {},
                    { correlationId },
                );

                const { headers } = await postRequest(url, { username: phoneNumber, otp });
                setTimer(0);
                if (onSuccess) {
                    onSuccess(headers.authorization, phoneNumber, userAlreadyExists);
                }
            } catch (e) {
                captureException(e);
                const error = getErrorForFieldOrGeneric(e, 'otp');
                setErrorMessage(error);
            } finally {
                setIsPending(false);
            }
        },
        [phoneNumber, correlationId, setIsPending, setErrorMessage, userAlreadyExists],
    );

    const login = useCallback(
        async (phoneNumber: string, password: string) => {
            try {
                setIsPending(true);
                const url = correlationId ? makeUrl(Endpoint.LOGIN, {}, { correlationId }) : Endpoint.LOGIN;
                const { headers } = await postRequest(url, { username: phoneNumber, password }, { 'X-Login-As': role });
                setTimer(0);
                if (onSuccess) {
                    onSuccess(headers.authorization, phoneNumber, userAlreadyExists);
                }
            } catch (e) {
                captureException(e);
                const error = getErrorForFieldOrGeneric(e, 'otp');
                if (error) {
                    setErrorMessage(error);
                } else {
                    if (e.isAxiosError && e.response && e.response.status) {
                        switch (e.response.status) {
                            case 401:
                                action(makeSnackbarErrorAction, 'auth:login.invalidPassword');
                                break;
                            case 429:
                                action(makeSnackbarErrorAction, 'auth:login.loginTimeout');
                                break;
                            default:
                                action(makeSnackbarErrorAction, 'auth:login.authError');
                                break;
                        }
                    }
                }
            } finally {
                setIsPending(false);
            }
        },
        [phoneNumber, correlationId, setIsPending, setErrorMessage, setTimer, userAlreadyExists],
    );

    return {
        stage,
        setStage,
        phoneNumber,
        setPhoneNumber,
        isPending,
        timer,
        errorMessage,
        setErrorMessage,
        shouldTryAgainLater,
        generateOtp,
        verifyOtp,
        login,
    };
};

export const usePhoneVerification = (onSuccess: (phoneNumber: string) => void) => {
    const [stage, setStage] = useState(PhoneVerificationStage.PHONE);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [timer, setTimer] = useState(0);
    const [isPending, setIsPending] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldTryAgainLater, setShouldTryAgainLater] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (timer > 0) {
            timeout = setTimeout(() => setTimer(timer - 1), 1000);
        }
        return () => clearTimeout(timeout);
    }, [timer]);

    const generateOtp = useCallback(async (phoneNumber: string, captcha?: string) => {
        if (timer > 0) {
            setShouldTryAgainLater(true);
            return;
        } else {
            setShouldTryAgainLater(false);
        }
        try {
            setIsPending(true);
            await postRequest(Endpoint.PHONE_OTP_SEND, { phoneNumber, captcha });
            setStage(PhoneVerificationStage.OTP);
        } catch (e) {
            captureException(e);
            const error = getErrorForFieldOrGeneric(e, 'phoneNumber');
            setErrorMessage(error);
        } finally {
            setTimer(appConfig.otpTimeout);
            setIsPending(false);
        }
    }, []);

    const verifyOtp = useCallback(async (phoneNumber: string, otp: string) => {
        try {
            setIsPending(true);
            await postRequest(Endpoint.PHONE_OTP_VERIFY, { phoneNumber, otp });
            setTimer(0);
            onSuccess(phoneNumber);
        } catch (e) {
            captureException(e);
            const error = getErrorForFieldOrGeneric(e, 'otp');
            setErrorMessage(error);
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        stage,
        setStage,
        phoneNumber,
        setPhoneNumber,
        isPending,
        timer,
        errorMessage,
        setErrorMessage,
        generateOtp,
        verifyOtp,
        shouldTryAgainLater,
    };
};
