import React from 'react';
import i18n from '../../../i18n';

import { CallButton, useIconButtonStyles } from 'components/CallButton/CallButton';
import { Text, TextTypography } from 'components/Text/Text';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/main-16/chat--16.svg';
import IconButton from '@material-ui/core/IconButton';
import { UserRole } from 'types';

interface OrderCustomerPhoneProps {
    phoneNumber: string;
    userRole: UserRole;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
    },
    phoneNumber: {
        flex: 1,
    },
    buttons: {
        paddingLeft: theme.spacing(1),
    },
}));

export const OrderCustomerPhone = (props: OrderCustomerPhoneProps) => {
    const { phoneNumber, userRole } = props;
    const { t } = i18n.useTranslation();
    const classes = useStyles();
    const trimmedPhoneNumber = phoneNumber ? phoneNumber.replace(/\D+/g, '') : phoneNumber;
    return (
        <div className={classes.container}>
            <div className={classes.phoneNumber}>
                <Text component="div" typography={TextTypography.BODY_ACCENT}>
                    {userRole === UserRole.CUSTOMER ? t('orders:dialog.customerPhone') : t('orders:dialog.vendorPhone')}
                </Text>
                <Text component="div" typography={TextTypography.PARA}>
                    {phoneNumber}
                </Text>
            </div>
            <CallButton phoneNumber={phoneNumber} />
            <div className={classes.buttons}>
                <IconButton
                    classes={useIconButtonStyles()}
                    component="a"
                    href={`https://wa.me/${trimmedPhoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <SvgIcon component={WhatsAppIcon} viewBox="0 0 16 16" />
                </IconButton>
            </div>
        </div>
    );
};
