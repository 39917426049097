import React, { FC, Fragment } from 'react';

import { formatISODateTime } from 'core/date/date';

export interface DateTimeProps {
    children: string;
}

export const DateTime: FC<DateTimeProps> = props => {
    return <Fragment>{formatISODateTime(props.children)}</Fragment>;
};
