import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { postRequest } from 'core/axios/axios';
import { getLocalStorageItem, LocalStorageKey } from 'core/storage/storage';
import { makeUrl } from 'core/utils/utils';

import { CircularProgressPage } from 'components/CircularProgressPage/CircularProgressPage';
import { PaymentForm } from 'components/PaymentForm/PaymentForm';

import { Endpoint } from 'Endpoint';

export const CheckoutPaymentForm = () => {
    const [confirmationToken, setConfirmationToken] = useState<string>(null);
    const path = window.location.href;
    
    useEffect(() => {
        const orderId = getLocalStorageItem(LocalStorageKey.CART_ORDER_ID);
        const url = makeUrl(Endpoint.ONLINE_PAYMENT, { orderId }, { returnUrl: path });
        postRequest(url, {})
            .then(response => {
                // setConfirmationToken(response.data.confirmationToken);
                window.location.assign(response.data.confirmationRedirect);
            })
            .catch(e => console.log(e));
    }, []);
    return confirmationToken ? (
        <Box m={2}>
            <PaymentForm confirmationToken={confirmationToken} returnUrl={path + '?complete=1'} />
        </Box>
    ) : (
        <CircularProgressPage />
    );
};
