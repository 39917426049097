import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { useActivate } from 'core/store/store';
import { putRequest } from 'core/axios/axios';

import { makeUserMenuOrdersFetchAction, UserMenuActionType } from 'state/userMenu/actions';
import { userMenuOrdersSelector } from 'state/userMenu/selector';

import { OrderList } from 'components/OrderList/OrderList';
import { makeSnackbarErrorAction } from '../../state/snackbar/actions';

export interface OrdersCardProps {
    onOrderClick: (storeId: string, orderId: string) => void;
    onClose?: () => void;
}

export const OrdersCard = (props: OrdersCardProps) => {
    useActivate(UserMenuActionType.ORDERS_ACTIVATE, UserMenuActionType.ORDERS_DEACTIVATE);
    const items = useSelector(userMenuOrdersSelector);

    // ANY-1673 Disable sorting
    // const sorting = {
    //     field: 'orderCreationDateTime' as keyof OrderListResponseItem,
    //     direction: SortingDirection.ASC,
    // };

    const dispatch = useDispatch();

    const handleChangeRating = useCallback(async (orderId: string, value: number) => {
        try {
            await putRequest(`/api/orders/${orderId}/rating`, { value });
            dispatch(makeUserMenuOrdersFetchAction());
        } catch (error) {
            dispatch(makeSnackbarErrorAction('common:errors.orderGrade'));
        }
    }, []);

    return (
        <Box m={2}>
            <OrderList orders={items} showRating={true} onClick={props.onOrderClick} onRate={handleChangeRating} />
        </Box>
    );
};
