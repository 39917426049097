import React from 'react';
import i18n from '../../../i18n';
import { connect, useSelector } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import { useFormError, validator } from '../../core/form/form';

import { isUIEnabledSelector } from '../../state/app/selectors';

import { FormErrorMessage } from '../../components/FormErrorMessage/FormErrorMessage';
import { LargeButton, LargeButtonColor } from '../../components/LargeButton/LargeButton';

import { Form } from '../types';
import { TextControlField } from '../../components/TextControl/TextControl';
import { Footer } from '../../components/Footer/Footer';
import { MapControlField } from '../../components/MapControl/MapControl';
import { SwitchControlField } from '../../components/SwitchControl/SwitchControl';
import { useAction } from '../../core/store/store';
import { UserMenuSlice } from '../../state/userMenu/reducer';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { string } from 'yup';
import { makeUserMenuAddressDeleteAction } from '../../state/userMenu/actions';
import { userMenuRoutingParamsSelector } from '../../state/userMenu/selector';

export interface AddressFormData {
    title: string;
    address: string;
    coordinates: { lat: number; lon: number };
    comment: string;
    isDefault: boolean;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
    },
    controlFields: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    field: {
        marginTop: theme.spacing(3),
    },
    comment: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    isDefault: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
    },
    button: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
}));

const mapStateToFormProps = (state: UserMenuSlice) => {
    return {
        initialValues: {
            ...state.userMenu.address,
        },
    };
};

export const AddressForm = connect(mapStateToFormProps)(
    reduxForm({
        form: Form.ADDRESS_FORM,
        enableReinitialize: true,
        validate: validator({
            address: string().required('common:validation.required'),
        }),
    })((props: any) => {
        const { handleSubmit, initialValues } = props;
        const isUIEnabled = useSelector(isUIEnabledSelector);
        const routeParams = useSelector(userMenuRoutingParamsSelector);
        const action = useAction();
        const { t } = i18n.useTranslation();
        const error = useFormError(Form.ADDRESS_FORM);
        const classes = useStyles();
        return (
            <ErrorBoundary>
                <form onSubmit={handleSubmit} className={classes.container}>
                    <div>
                        <div className={classes.controlFields}>
                            <div className={classes.field}>
                                <Field
                                    name="address"
                                    component={MapControlField}
                                    label={t('common:userMenu.address.title')}
                                    initWithGeolocationData={!routeParams?.addressId}
                                    initialCoordinates={initialValues.coordinates}
                                    value={initialValues.address}
                                    buttonText="OK"
                                    onCoordinatesChange={(coordinates: { lat: number; lon: number }) => {
                                        action(change, Form.ADDRESS_FORM, 'coordinates', coordinates);
                                    }}
                                />
                            </div>
                            <div className={classes.field}>
                                <Field
                                    name="title"
                                    component={TextControlField}
                                    label={t('common:userMenu:address:name')}
                                />
                            </div>
                            {error && (
                                <div className={classes.field}>
                                    <FormErrorMessage error={error} />
                                </div>
                            )}
                        </div>
                        <div className={classes.comment}>
                            <Field
                                name="comment"
                                component={TextControlField}
                                label={t('common:userMenu:address:comment')}
                            />
                        </div>
                        <div className={classes.isDefault}>
                            <Field
                                name="isDefault"
                                component={SwitchControlField}
                                label={t('common:userMenu:address:isDefault')}
                            />
                        </div>
                    </div>
                    <div>
                        {routeParams?.addressId && (
                            <div className={classes.button}>
                                <LargeButton
                                    color={LargeButtonColor.ERROR}
                                    onClick={() => action(makeUserMenuAddressDeleteAction)}
                                >
                                    {t('common:userMenu:address:deleteButton')}
                                </LargeButton>
                            </div>
                        )}
                        <Footer className={classes.button}>
                            <LargeButton disabled={!!error || !isUIEnabled} type="submit">
                                {t('common:userMenu:address:saveButton')}
                            </LargeButton>
                        </Footer>
                    </div>
                </form>
            </ErrorBoundary>
        );
    }),
);
