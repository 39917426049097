import React, { FC } from 'react';

import { Button, ButtonColor } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';

import { OrderChangeStatusIntent } from 'types';

import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';

export interface WaitingForApprovalOrderActionsProps {
    onIntent: (intent: OrderChangeStatusIntent) => void;
    isPending: boolean;
}

export const WaitingForApprovalOrderActions: FC<WaitingForApprovalOrderActionsProps> = ({ onIntent, isPending }) => {
    return (
        <div>
            <Button
                disabled={isPending}
                color={ButtonColor.ERROR}
                onClick={() => onIntent(OrderChangeStatusIntent.DECLINE)}
            >
                <Icon component={CloseIcon} size={16} svgSize={16} />
            </Button>{' '}
            <Button
                disabled={isPending}
                color={ButtonColor.SUCCESS}
                onClick={() => {
                    onIntent(OrderChangeStatusIntent.ACCEPT_BY_CUSTOMER);
                }}
            >
                OK
            </Button>
        </div>
    );
};
