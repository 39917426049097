import { WrappedFieldProps } from 'redux-form';
import MaskedInput from 'react-text-mask';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { TextField } from '@material-ui/core';

interface DateMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

const DateMaskCustom = (props: DateMaskCustomProps) => {
    const { inputRef, ...other } = props;

    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            pipe={autoCorrectedDatePipe}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
        />
    );
};

export interface KeyboardDatePickerControlFieldOwnProps {
    label: string;
    // Optional
    autoFocus?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
}

const useInputStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(0.75),
        paddingLeft: 0,
    },
}));

export type KeyboardDatePickerControlFieldProps = KeyboardDatePickerControlFieldOwnProps & WrappedFieldProps;

export const KeyboardDatePickerControlField = (props: KeyboardDatePickerControlFieldProps) => {
    const {
        input,
        autoFocus,
        isDisabled,
        isReadOnly,
        meta: { error },
        ...rest
    } = props;
    const inputClasses = useInputStyles();
    return (
        <TextField
            InputProps={{
                classes: inputClasses,
                disableUnderline: true,
                readOnly: isReadOnly,
                inputComponent: DateMaskCustom as any,
            }}
            autoFocus={autoFocus}
            variant="filled"
            error={!!error}
            helperText={error}
            fullWidth={true}
            size="small"
            disabled={isDisabled}
            {...rest}
            {...input}
        />
    );
};
