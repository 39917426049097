import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { Box, FormControlLabel, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { feedAddressSelector } from 'state/feed/selectors';
import {
    checkoutCartDeliveryTypeSelector,
    checkoutCartSelector,
    checkoutCartStoreInfoSelector,
} from 'state/checkout/selectors';
import { Text, TextTypography } from 'components/Text/Text';

import { DeliveryType } from 'types';

import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';

import { useAction } from 'core/store/store';
import { ReactComponent as DeliveryIcon } from 'assets/glyphs/delivery.svg';
import { ReactComponent as PickUpIcon } from 'assets/glyphs/pick-up.svg';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon/Icon';
import { makeCheckoutChoseDeliveryAction } from 'state/checkout/actions';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { getCurrencyText } from '../../core/utils/utils';

export interface CheckoutCartDeliveryProps {
    isFullScreen?: boolean;
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    header: {
        height: 87,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    orderTextWrapper: {
        alignText: 'center',
        textAlign: 'center',
        flex: 1,
    },
    mainContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'rgba(15, 24, 37, 0.05)',
    },
    containerScroll: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(3),
    },
    deliveryTypesContainer: {
        marginTop: theme.spacing(2),
    },
    icon: {
        display: 'block',
        fill: theme.palette.brandPrimary.main,
        height: theme.spacing(2),
        width: theme.spacing(2),
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
    logoIcon: {
        cursor: 'pointer',
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    iconContainer: {
        cursor: 'pointer',
        borderRadius: theme.spacing(2),
        height: theme.spacing(4),
        padding: theme.spacing(1),
        width: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    address: {
        height: 139,
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    addressLink: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    addressOpenIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    map: {
        marginRight: theme.spacing(2),
    },
    cartItem: {
        paddingTop: theme.spacing(3),
        '&:first-child': {
            paddingTop: 0,
        },
    },
    checkoutSettings: {
        display: 'flex',
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    checkoutSetting: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
        '&:first-child': {
            borderRight: `1px solid ${theme.palette.brandPrimary.accent}`,
        },
    },
    badge: {
        height: theme.spacing(2),
        width: 40,
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedSuccess.main,
    },
    badgeWithSum: {
        height: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedPrimary.primary,
    },
    checkout: {
        cursor: 'pointer',
        padding: theme.spacing(2),
    },
    paymentText: {
        color: theme.palette.fixedCritical.main,
    },
    paymentChoose: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioElement: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        backgroundColor: '#FBFEF9',
        boxShadow: '0px 0px 50px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        marginBottom: theme.spacing(3),
    },
    deliveryRadio: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addressInfo: {
        height: 'calc(100% - 80px)',
        display: 'flex',
        flexDirection: 'column',
    },
    addressElement: {
        paddingTop: theme.spacing(3),
    },
    addressText: {
        textAlign: 'center',
        display: 'flex',
        minHeight: 72,
    },
    emptyBody: {
        padding: theme.spacing(6),
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    emptyBodyMessage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        paddingBottom: theme.spacing(3),
    },
    successMessage: {
        backgroundColor: theme.palette.fixedSuccess.accent,
        borderWidth: 2,
        borderColor: theme.palette.fixedSuccess.main,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1.25),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        maxWidth: 352,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '0px 10px 20px rgba(36, 49, 67, 0.2)',
    },
    successMessageTitle: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    successButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down(1340)]: {
            flexDirection: 'column',
        },
    },
    buttonElement: {
        color: '#45CA52',
        cursor: 'pointer',
    },
    radioIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioLabel: {
        paddingLeft: theme.spacing(2),
    },
}));

export const CheckoutCartDelivery = (props: CheckoutCartDeliveryProps) => {
    const { isFullScreen, onClose } = props;
    const address = useSelector(feedAddressSelector);
    const storeInfo = useSelector(checkoutCartStoreInfoSelector);
    const action = useAction();
    const { t } = i18n.useTranslation();
    const classes = useStyles({ isFullScreen });
    const cart = useSelector(checkoutCartSelector);

    const deliveryType: DeliveryType = useSelector(checkoutCartDeliveryTypeSelector);

    const storeDeliveryInfo: Record<DeliveryType, { deliveryPrice: number }> = cart?.availableDeliveries?.reduce(
        (acc, delivery) => ({ ...acc, [delivery.deliveryType]: { deliveryPrice: delivery.deliveryPrice } }),
        {} as Record<DeliveryType, { deliveryPrice: number }>,
    );

    const storeDeliveryOrDeliveryService =
        storeDeliveryInfo && storeDeliveryInfo[DeliveryType.STORE_DELIVERY]
            ? DeliveryType.STORE_DELIVERY
            : DeliveryType.DELIVERY_SERVICE;

    return (
        <ErrorBoundary>
            <Box className={classes.mainContainer}>
                <div className={classes.header}>
                    <div className={classes.iconContainer} onClick={onClose}>
                        <Icon component={BackIcon} size={16} svgSize={16} className={classes.icon} />
                    </div>
                    <div className={classes.orderTextWrapper}>
                        <Box textAlign={'center'}>
                            <Text typography={TextTypography.LEAD_ACCENT}>{t('feed:checkout:shipping.title')}</Text>
                        </Box>
                    </div>
                    <div className={classes.iconContainer} />
                </div>
                <div className={classes.containerScroll}>
                    <Text typography={TextTypography.PARA}>{t('feed:checkout:shipping.choose2')}</Text>
                    <RadioGroup
                        className={classes.deliveryTypesContainer}
                        name={'deliveryType'}
                        style={{ width: '100%' }}
                        onChange={value => action(makeCheckoutChoseDeliveryAction, value.target.value)}
                    >
                        {storeDeliveryInfo && storeDeliveryInfo[storeDeliveryOrDeliveryService] && (
                            <div className={classes.radioElement}>
                                <FormControlLabel
                                    checked={deliveryType === storeDeliveryOrDeliveryService}
                                    disabled={!storeDeliveryInfo[storeDeliveryOrDeliveryService]}
                                    value={storeDeliveryOrDeliveryService}
                                    control={<RadioButton />}
                                    label={
                                        <div className={classes.radioLabel}>
                                            <Text typography={TextTypography.PARA_ACCENT}>
                                                {t('feed:checkout:shipping:STORE_DELIVERY')}
                                            </Text>
                                            <Text typography={TextTypography.BODY}>
                                                {storeDeliveryInfo[storeDeliveryOrDeliveryService] &&
                                                    !!deliveryType &&
                                                    !!address &&
                                                    (storeDeliveryInfo[storeDeliveryOrDeliveryService].deliveryPrice > 0
                                                        ? `${
                                                              storeDeliveryInfo[storeDeliveryOrDeliveryService]
                                                                  .deliveryPrice
                                                          }${getCurrencyText()}`
                                                        : t('feed:checkout:shipping.free'))}
                                            </Text>
                                        </div>
                                    }
                                />
                                <div className={classes.radioIcon}>
                                    <Icon component={DeliveryIcon} size={32} svgSize={32} />
                                </div>
                            </div>
                        )}
                        {storeDeliveryInfo[DeliveryType.PICKUP] && (
                            <div className={classes.radioElement}>
                                <FormControlLabel
                                    checked={deliveryType === DeliveryType.PICKUP}
                                    disabled={!storeDeliveryInfo[DeliveryType.PICKUP]}
                                    value={DeliveryType.PICKUP}
                                    control={<RadioButton />}
                                    label={
                                        <div className={classes.radioLabel}>
                                            <Text typography={TextTypography.PARA_ACCENT}>
                                                {t('feed:checkout:shipping:PICKUP')}
                                            </Text>
                                            <Text typography={TextTypography.BODY}>
                                                {t('feed:checkout:shipping.pickupTime', {
                                                    time: storeInfo.storeDeliveries.find(
                                                        delivery => delivery.type === DeliveryType.PICKUP,
                                                    ).data[0].hoursToStore,
                                                })}
                                            </Text>
                                        </div>
                                    }
                                />
                                <div className={classes.radioIcon}>
                                    <Icon component={PickUpIcon} size={32} svgSize={32} />
                                </div>
                            </div>
                        )}
                        {storeDeliveryInfo[DeliveryType.SPECIFIC_DELIVERY] && (
                            <div className={classes.radioElement}>
                                <FormControlLabel
                                    checked={deliveryType === DeliveryType.SPECIFIC_DELIVERY}
                                    disabled={!storeDeliveryInfo[DeliveryType.SPECIFIC_DELIVERY]}
                                    value={DeliveryType.SPECIFIC_DELIVERY}
                                    control={<RadioButton />}
                                    label={
                                        <div className={classes.radioLabel}>
                                            <Text typography={TextTypography.PARA_ACCENT}>
                                                {t('feed:checkout:shipping:SPECIFIC_DELIVERY')}
                                            </Text>
                                            <Text typography={TextTypography.BODY}>
                                                {t('feed:checkout.delivery.specific')}
                                            </Text>
                                        </div>
                                    }
                                />
                                <div className={classes.radioIcon}>
                                    <Icon component={DeliveryIcon} size={32} svgSize={32} />
                                </div>
                            </div>
                        )}
                    </RadioGroup>
                </div>
                <div>
                    <div className={classes.checkout}>
                        <LargeButton color={LargeButtonColor.SUCCESS} onClick={onClose}>
                            {t('feed:checkout:continue')}
                        </LargeButton>
                    </div>
                </div>
                <CustomerMenu navigationPath={CustomerMenuSelectedItem.CHECKOUT} />
            </Box>
        </ErrorBoundary>
    );
};
