import React from 'react';
import { useSelector } from 'react-redux';

import { useAction } from 'core/store/store';

import { userMenuRoutingSelectedItemSelector } from 'state/userMenu/selector';
import { UserMenuSelectedItem } from 'state/userMenu/reducer';
import { makeUserMenuNavigateAction } from 'state/userMenu/actions';

import { MenuOrderCard } from './MenuOrderCard';
import { OrdersCard } from './OrdersCard';
import { ProfileCard } from './ProfileCard';
import { AddressesCard } from './AddressesCard';
import { MenuAddressCard } from './MenuAddressCard';

export interface UserMenuContentsProps {
    onClose: () => void;
}

export const UserMenuContents = () => {
    const userMenuRoutingSelectedItem = useSelector(userMenuRoutingSelectedItemSelector);
    const action = useAction();
    switch (userMenuRoutingSelectedItem) {
        case UserMenuSelectedItem.PROFILE:
            return <ProfileCard onClose={() => action(makeUserMenuNavigateAction, UserMenuSelectedItem.MENU)} />;
        case UserMenuSelectedItem.ORDERS:
            return (
                <OrdersCard
                    onOrderClick={(storeId, orderId) => {
                        action(makeUserMenuNavigateAction, UserMenuSelectedItem.ORDER, { orderId, storeId });
                    }}
                    onClose={() => action(makeUserMenuNavigateAction, UserMenuSelectedItem.MENU)}
                />
            );
        case UserMenuSelectedItem.ORDER:
            return <MenuOrderCard />;
        case UserMenuSelectedItem.ADDRESSES:
            return (
                <AddressesCard
                    onAddressClick={addressId => {
                        action(makeUserMenuNavigateAction, UserMenuSelectedItem.ADDRESS, { addressId });
                    }}
                />
            );
        case UserMenuSelectedItem.ADDRESS:
            return <MenuAddressCard />;
    }
};
