import React from 'react';
import { Text, TextTypography } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { UserRole } from 'types';
import css from './MenuItem.module.css';

export interface MenuItemProps {
    iconComponent: any;
    title: string;
    onClick: () => void;
    visibleForRole?: UserRole;
    badgeText?: string;
    svgSize?: number;
}

export const MenuItem = (props: MenuItemProps) => {
    const { iconComponent, title, badgeText, onClick, svgSize } = props;

    return (
        <div className={css.container} onClick={onClick}>
            <div className={css.main}>
                <div className={css.icon}>
                    <Icon component={iconComponent} size={16} svgSize={svgSize || 16} color={'#243143'} />
                </div>
                <Text typography={TextTypography.LEAD_ACCENT}>{title}</Text>
            </div>
            {badgeText && (
                <div className={css.badge}>
                    <Text typography={TextTypography.CAPTION_ACCENT} className={css.text_color_orange}>
                        {badgeText}
                    </Text>
                </div>
            )}
        </div>
    );
};
