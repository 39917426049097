import { makeStyles } from '@material-ui/core';
import { Text, TextTypography } from 'components/Text/Text';
import { useAction } from 'core/store/store';
import { useCallback, useMemo } from 'react';
import i18n from '../../../i18n';
import { makeCheckoutChangePromoAction } from 'state/checkout/actions';
import React from 'react';
import css from './CheckoutPromoItemFooter.module.css';

export interface CheckoutPromoItemFooterProps {
    promoId: string;
    activePromoId: string;
    onClose: () => void;
}

export const CheckoutPromoItemFooter = (props: CheckoutPromoItemFooterProps) => {
    const { activePromoId, promoId, onClose } = props;
    const isActivePromo = useMemo(() => promoId === activePromoId, [promoId, activePromoId]);
    const action = useAction();
    const { t } = i18n.useTranslation();

    const handlePromoItemClick = useCallback(() => {
        action(makeCheckoutChangePromoAction, isActivePromo ? null : promoId);
        onClose();
    }, [action, isActivePromo, promoId, onClose]);

    return (
        <div className={css.promoItemFooter} onClick={handlePromoItemClick}>
            {isActivePromo ? (
                <div>
                    <Text className={css.text_color_green} typography={TextTypography.PARA_ACCENT}>
                        {t('feed:checkout.promos.cancel')}
                    </Text>
                </div>
            ) : (
                <div>
                    <Text className={css.text_color_green} typography={TextTypography.PARA_ACCENT}>
                        {t('feed:checkout.promos.apply')}
                    </Text>
                </div>
            )}
        </div>
    );
};
