import classNames from 'classnames';
import React, { ReactNode, useCallback, useState } from 'react';
import { HorizontalDivider } from 'components/HorizontalDivider/HorizontalDivider';
import { Icon } from 'components/Icon/Icon';
import { makePromoDeleteRequestAction, makePromoToggleActivePromoAction } from 'state/promo/actions';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency, makeUrl } from 'core/utils/utils';
import { PromoDto, PromoLabel } from 'types';
import { ReactComponent as PromoGiftIcon } from 'assets/icons/main-24/gift-large--24.svg';
import { ReactComponent as PromoPercentIcon } from 'assets/icons/main-24/promo-percent-star--24.svg';
import { ReactComponent as PromoEditIcon } from 'assets/icons/main-16/edit-o--16.svg';
import { ReactComponent as PromoDeleteIcon } from 'assets/icons/main-16/trash--16.svg';
import { RoutePath } from 'RoutePath';
import { SwitchControl } from 'components/SwitchControl/SwitchControl';
import { Text, TextTypography } from 'components/Text/Text';
import { useAction } from 'core/store/store';
import { useHistory } from 'react-router-dom';
import i18n from '../../../../../i18n';
import { authIsoCodeSelector } from '../../../../state/auth/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    promoItemContainer: {
        height: 201,
        width: 384,
        borderRadius: 20,
        background: '#FDFFFC',
        boxShadow: '0px 0px 20px rgba(15, 24, 37, 0.05)',
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(400)]: {
            height: 220,
        },
    },
    promoItemInfoContainer: {
        flex: 1,
        display: 'flex',
        padding: '16px 16px 0px 16px',
    },
    promoItemLogoContainer: {
        width: theme.spacing(5),
    },
    promoItemTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'initial',
        paddingLeft: theme.spacing(2),
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    promoItemText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    promoItemConditionContainer: {
        marginTop: 0,
        paddingLeft: theme.spacing(2),
    },
    promoItemFooter: {
        minHeight: 55,
        flex: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    inactivePromo: {
        opacity: 0.5,
    },
    footerCentalItem: {
        padding: '0px 24px',
    },
    footerItemWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
    footerApplyButton: {
        flex: 1,
        cursor: 'pointer',
    },
    dividerStyle: {
        backgroundColor: theme.palette.brandPrimary.lightest,
    },
    activeModeWrapper: {
        [theme.breakpoints.down(390)]: {
            marginRight: 3,
        },
    },
}));

export interface VendorPromoListItemProps {
    promo: PromoDto;
    className?: string;
    customFooter?: ReactNode;
}

export const VendorPromoListItem = (props: VendorPromoListItemProps) => {
    const { promo, className, customFooter } = props;
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    const action = useAction();
    const history = useHistory();
    const { results = [], conditions } = promo;
    const isoCode = useSelector(authIsoCodeSelector);

    const [isActivePromo, setIsActivePromo] = useState(promo.active);

    const handleToggleActivePromoState = useCallback(
        (active: boolean) => {
            setIsActivePromo(active);
            action(makePromoToggleActivePromoAction, active, promo.promoId);
        },
        [setIsActivePromo, promo.promoId],
    );

    const handleDeletePromo = useCallback(() => {
        const message = t('vendor:promos.list.confirmDelete');
        const result = window.confirm(message);
        if (result) {
            action(makePromoDeleteRequestAction, promo.promoId);
        }
    }, [action, promo.promoId]);

    const handleEditPromo = useCallback(() => {
        const editUrl = makeUrl(RoutePath.VENDOR_PROMO_EDIT, {
            promoId: promo.promoId,
        });
        history.push(editUrl);
    }, [history, promo.promoId]);

    return (
        <div className={classNames(classes.promoItemContainer, className)}>
            <div className={classNames(classes.promoItemInfoContainer, !promo.active && classes.inactivePromo)}>
                <div className={classes.promoItemLogoContainer}>
                    {promo.image === PromoLabel.GIFT ? (
                        <Icon component={PromoGiftIcon} svgSize={40} size={40} />
                    ) : (
                        <Icon component={PromoPercentIcon} svgSize={40} size={40} />
                    )}
                </div>
                <div className={classes.promoItemTextContainer}>
                    <Text className={classes.promoItemText} typography={TextTypography.BODY}>
                        {promo.description}
                    </Text>
                    <ul className={classes.promoItemConditionContainer}>
                        <li>
                            <Text typography={TextTypography.BODY}>
                                {t('vendor:promos.list.minPrice', {
                                    minOrderPrice: formatCurrency(conditions?.minOrderPrice, isoCode)
                                })}
                            </Text>
                        </li>
                        <li>
                            <Text typography={TextTypography.BODY}>
                                {t(`common:enums.PROMO_DELIVERY_TYPE.${conditions?.deliveryType}`)}
                            </Text>
                        </li>
                    </ul>
                </div>
            </div>
            <HorizontalDivider className={classes.dividerStyle} />
            {customFooter ? (
                customFooter
            ) : (
                <div className={classes.promoItemFooter}>
                    <div className={classes.footerItemWrapper} onClick={handleEditPromo}>
                        <Icon component={PromoEditIcon} svgSize={16} size={16} />
                        <Text typography={TextTypography.PARA_ACCENT}>{t('vendor:promos.list.edit')}</Text>
                    </div>
                    <div
                        className={classNames(classes.footerCentalItem, classes.footerItemWrapper)}
                        onClick={handleDeletePromo}
                    >
                        <Icon component={PromoDeleteIcon} svgSize={16} size={16} />
                        <Text typography={TextTypography.PARA_ACCENT}>{t('vendor:promos.list.delete')}</Text>
                    </div>

                    <div>
                        <SwitchControl
                            className={classes.activeModeWrapper}
                            label={''}
                            value={isActivePromo}
                            onChange={handleToggleActivePromoState}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
