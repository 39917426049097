import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SelectControlDropdown } from './SelectControlDropdown';

interface SelectControlProps {
    isOpen: boolean;
    label: string;
    onOpen: () => void;
    onClose: () => void;
    // Optional:
    isDisabled: boolean;
    isReadOnly: boolean;
    value: string;
}

const useInputStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(0.75),
        paddingLeft: 0,
    },
}));

export const SelectControl: FC<SelectControlProps> = props => {
    const { children, isReadOnly, isDisabled, isOpen, onOpen, onClose, ...rest } = props;

    const containerRef = useRef<HTMLDivElement>();
    const containerWidth = containerRef.current?.getBoundingClientRect().width;

    const inputClasses = useInputStyles();
    return (
        <div
            ref={r => {
                containerRef.current = r;
            }}
        >
            <TextField
                InputProps={{
                    classes: inputClasses,
                    disableUnderline: true,
                    readOnly: isReadOnly,
                }}
                variant="filled"
                fullWidth={true}
                size="small"
                disabled={isDisabled}
                onClick={onOpen}
                {...rest}
            />
            {isOpen && (
                <SelectControlDropdown anchorElement={containerRef.current} onClose={onClose} width={containerWidth}>
                    {children}
                </SelectControlDropdown>
            )}
        </div>
    );
};
