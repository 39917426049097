import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import { v4 } from 'uuid';

import { appConfig } from 'config/app';

import { getRequest } from 'core/axios/axios';
import { makeUrl } from 'core/utils/utils';

import { TextControl } from 'components/TextControl/TextControl';

import { Endpoint } from 'Endpoint';
import { MapControlAddressValidation } from './MapControlAddressValidation';
import { Text, TextTypography } from '../Text/Text';
import i18n from '../../../i18n';

export interface AutocompleteOptionMatchedSubstring {
    length: number;
    offset: number;
}

export type AutocompleteOptionType = 'street_address' | 'geocode';

export interface AutocompleteOption {
    structured_formatting: {
        main_text: string;
        main_text_matched_substrings: AutocompleteOptionMatchedSubstring[];
        secondary_text: string;
    };
    types: AutocompleteOptionType[];
}

interface AutocompletePredictionsOptions {
    input: string;
    token: string;
    location: {
        lat: number;
        lon: number;
    };
    radius: number;
}

const getAutocompletePredictions = (
    endpoint: Endpoint,
    options: AutocompletePredictionsOptions,
): Promise<AutocompleteOption[]> => {
    const { location, radius, ...rest } = options;
    const lat = location.lat ?? appConfig.defaultCoordinates.lat;
    const lon = location.lon ?? appConfig.defaultCoordinates.lon;
    const url = makeUrl(endpoint, {
        ...rest,
        radius: radius ?? 50e6,
        location: `${lat},${lon}`,
    });
    return getRequest(url).then(response => {
        const { status, predictions } = response.data;
        return status === 'OK' && predictions ? predictions : [];
    });
};

export interface MapControlAutocompleteViewProps {
    label: string;
    value: string;
    onSelect: (value: string, isStreetAddress: boolean) => void;
    location: {
        lat: number;
        lon: number;
    };
    radius: number;
    endpoint: Endpoint.CITY_AUTOCOMPLETE | Endpoint.ADDRESS_AUTOCOMPLETE;
    // Optional:
    displayValidation?: boolean;
    filterBy?: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    search: {
        flexBasis: 'auto',
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    list: {
        flex: 1,
    },
    validation: {
        flexBasis: 'auto',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
}));

export const MapControlAutocomplete = (props: MapControlAutocompleteViewProps) => {
    const { label, value, onSelect, endpoint, location, radius, filterBy, displayValidation } = props;
    const [input, setInput] = useState(value);
    const [options, setOptions] = useState([]);
    const token = useRef(v4());
    const { t } = i18n.useTranslation();

    const [hasValidAddress, setHasValidAddress] = useState(false);
    const [hasStreetAddress, setHasStreetAddress] = useState(false);

    useEffect(() => {
        if (input.length < 3) {
            return;
        }
        const options = {
            input,
            token: token.current,
            location,
            radius,
        };
        getAutocompletePredictions(endpoint, options)
            .then(options => {
                const streetAddressMatch = options.find(option => {
                    const mainText = option.structured_formatting.main_text;
                    return input.includes(mainText) || mainText.includes(input);
                });
                setHasValidAddress(!!streetAddressMatch);

                const houseNumberMatch = options.find(option => {
                    const { structured_formatting, types } = option;
                    return structured_formatting.main_text === input && types.includes('street_address');
                });
                setHasStreetAddress(!!houseNumberMatch);

                if (filterBy) {
                    const filteredOptions = options.filter(option => {
                        const { secondary_text } = option.structured_formatting;
                        return secondary_text.startsWith(filterBy + ',');
                    });
                    if (filteredOptions && filteredOptions.length) {
                        setOptions(filteredOptions);
                    } else {
                        setOptions(options);
                    }
                } else {
                    setOptions(options);
                }
            })
            .catch();
    }, [input, token, endpoint, location, radius, setHasStreetAddress, setHasValidAddress]);
    useEffect(() => {
        setInput(value);
    }, [value]);
    const searchRef = useRef<HTMLDivElement>();

    const handleSelectItem = useCallback(
        option => {
            const isAddress = option.types.includes('street_address');
            setHasStreetAddress(isAddress);
            onSelect(option.structured_formatting.main_text, isAddress);
            if (searchRef.current) {
                const input = searchRef.current.querySelector('input');
                if (input) {
                    input.focus();
                }
            }
        },
        [onSelect, searchRef, setHasStreetAddress],
    );
    const handleEnter = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Enter' && options.length > 0) {
                handleSelectItem(options[0]);
            }
        },
        [options, handleSelectItem],
    );
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {displayValidation && (
                <div className={classes.validation}>
                    <Text typography={TextTypography.BODY}>{t('common:addressControl.enter')}</Text>
                    <MapControlAddressValidation
                        hasValue={hasValidAddress}
                        hasStreetAddress={hasStreetAddress}
                        addressLabel={t('common:addressControl.streetAddress')}
                        houseNumberLabel={t('common:addressControl.houseNumber')}
                    />
                </div>
            )}
            <div
                className={classes.search}
                ref={r => {
                    searchRef.current = r;
                }}
            >
                <TextControl
                    autoFocus={true}
                    label={label}
                    value={input}
                    disableAutocomplete={true}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
                    onKeyPress={(e: KeyboardEvent) => handleEnter(e)}
                />
            </div>
            <List className={classes.list}>
                {options.map((option, i) => {
                    const { main_text, secondary_text, main_text_matched_substrings } = option.structured_formatting;
                    const parts = parse(
                        main_text,
                        main_text_matched_substrings.map((match: AutocompleteOptionMatchedSubstring) => {
                            return [match.offset, match.offset + match.length];
                        }),
                    );
                    return (
                        <ListItem key={i} button onClick={() => handleSelectItem(option)}>
                            <ListItemText
                                primary={
                                    <span>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </span>
                                }
                                secondary={secondary_text}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};
