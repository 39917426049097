import React, { useCallback, useMemo } from 'react';

import { ReactComponent as HappyIcon } from 'assets/icons/ratings/happy.svg';
import { ReactComponent as NeutralIcon } from 'assets/icons/ratings/neutral.svg';
import { ReactComponent as SadIcon } from 'assets/icons/ratings/sad.svg';
import { Icon } from 'components/Icon/Icon';

import css from './Rating.module.css';
import classNames from 'classnames';

interface RatingItemProps {
    Icon: React.Component;
    value: number | null;
    itemValue: number;
    onChange: (value: number) => void;
}

const RatingItem = ({ Icon, itemValue, value, onChange }: RatingItemProps) => {
    const isVisible = value === itemValue || value == null;

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (value == null) {
                event.stopPropagation();
                onChange(itemValue);
            }
        },
        [itemValue, onChange, value],
    );

    const style = useMemo(() => ({ visibility: isVisible ? 'visible' : 'hidden' }), [isVisible]);

    return (
        <div onClick={handleClick} className={classNames(css.item, value === itemValue && css.itemActive)}>
            <Icon component={Icon} style={style} color="transparent" />
        </div>
    );
};

interface RatingProps {
    value: number | null;
    onChange: (value: number) => void;
}

export const Rating = ({ value, onChange }: RatingProps) => {
    return (
        <div className={css.container}>
            <RatingItem Icon={HappyIcon} value={value} itemValue={1} onChange={onChange} />
            <RatingItem Icon={NeutralIcon} value={value} itemValue={0} onChange={onChange} />
            <RatingItem Icon={SadIcon} value={value} itemValue={-1} onChange={onChange} />
        </div>
    );
};
