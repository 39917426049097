import React, { FC } from 'react';

import { DeliveringOrderActions } from './DeliveringOrderActions';
import { PreparedOrderActions } from './PreparedOrderActions';
import { WaitingForApprovalOrderActions } from './WaitingForApprovalOrderActions';

import { OrderChangeStatusIntent, OrderStatus, UserRole } from 'types';

export interface OrderActionsProps {
    status: OrderStatus;
    userRole: UserRole;
    onIntent: (intent: OrderChangeStatusIntent) => void;
    isPending: boolean;
}

export const OrderActionsForCustomer: FC<OrderActionsProps> = ({ status, onIntent, isPending }) => {
    switch (status) {
        case OrderStatus.WAITING_FOR_APPROVAL:
            return <WaitingForApprovalOrderActions onIntent={onIntent} isPending={isPending} />;
        default:
            return null;
    }
};

export const OrderActionsForVendor: FC<OrderActionsProps> = ({ status, onIntent, isPending }) => {
    switch (status) {
        case OrderStatus.DELIVERY:
            return <DeliveringOrderActions onIntent={onIntent} isPending={isPending} />;
        case OrderStatus.PREPARING:
            return <PreparedOrderActions onIntent={onIntent} isPending={isPending} />;
        default:
            return null;
    }
};

export const OrderActions: FC<OrderActionsProps> = props => {
    switch (props.userRole) {
        case UserRole.CUSTOMER:
            return <OrderActionsForCustomer {...props} />;
        case UserRole.VENDOR:
            return <OrderActionsForVendor {...props} />;
        default:
            return null;
    }
};
