import React, { Fragment } from 'react';
import { Box } from '@material-ui/core';

import { AddressListItemData } from 'state/userMenu/types';
import { AddressListItem } from 'components/AddressesList/AddressListItem';

export interface AddressesListProps {
    addresses: AddressListItemData[];
    onAddressClick: (addressId: string) => void;
}

export const AddressesList = (props: AddressesListProps) => {
    return (
        <Fragment>
            {props.addresses.map(address => {
                return (
                    <Box mb={2} key={address.addressId}>
                        <AddressListItem
                            address={address.address}
                            isDefault={address.isDefault}
                            name={address.title}
                            onClick={() => {
                                props.onAddressClick(address.addressId);
                            }}
                        />
                    </Box>
                );
            })}
        </Fragment>
    );
};
