import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useAction } from 'core/store/store';

import { makeCheckoutCloseCartAction } from 'state/checkout/actions';
import { FullScreenDialog } from 'components/FullScreenDialog/FullScreenDialog';
import { Icon } from 'components/Icon/Icon';
import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Checkout } from 'pages/Checkout/Checkout';
import { checkoutShowCart } from 'state/checkout/selectors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        background: 'none',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    close: {
        position: 'absolute',
        left: -72,
        top: 24,
        width: 48,
        height: 48,
        borderRadius: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    menu: {
        position: 'relative',
        background: '#fff',
        width: 400,
    },
}));

export const CheckoutCartDialog = () => {
    const showMenuDialog = useSelector(checkoutShowCart);
    const action = useAction();
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const handleMenuClose = useCallback(() => {
        action(makeCheckoutCloseCartAction);
    }, [action]);

    if (showMenuDialog) {
        return (
            <ErrorBoundary>
                <FullScreenDialog onClose={handleMenuClose} className={classes.container}>
                    {({ onClose }) => (
                        <div className={classes.menu}>
                            {!isMobile && (
                                <div className={classes.close} onClick={onClose}>
                                    <Icon component={CloseIcon} size={16} svgSize={16} />
                                </div>
                            )}
                            <Checkout isFullScreen={false} />
                        </div>
                    )}
                </FullScreenDialog>
            </ErrorBoundary>
        );
    }

    return null;
};
