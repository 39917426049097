import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { useAction } from 'core/store/store';

import { UserMenuSelectedItem } from 'state/userMenu/reducer';
import { userMenuRoutingSelectedItemSelector } from 'state/userMenu/selector';
import { makeUserMenuNavigateAction } from 'state/userMenu/actions';

import { FullScreenDialog } from 'components/FullScreenDialog/FullScreenDialog';
import { Icon } from 'components/Icon/Icon';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Text, TextTypography } from 'components/Text/Text';
import { UserMenuNavigation } from './UserMenuNavigation';
import { UserMenuHeader } from './UserMenuHeader';
import { UserMenuContents } from './UserMenuContents';

import { ReactComponent as BackIcon } from 'assets/icons/main-24/back-o--24.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';

export interface UserMenuDialogProps {
    onClose: () => void;
    isSsr?: boolean;
}

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        padding: theme.spacing(2.5),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    closeButton: {
        cursor: 'pointer',
    },
    title: {
        paddingRight: theme.spacing(3),
        width: '100%',
        textAlign: 'center',
    },
    profile: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contents: {
        height: 'calc(100vh - 69px)',
        overflow: 'auto',
        flex: 1,
    },
}));

const UserMenu = (props: UserMenuDialogProps) => {
    const { onClose, isSsr } = props;
    const userMenuRoutingSelectedItem = useSelector(userMenuRoutingSelectedItemSelector);
    const action = useAction();
    const classes = useStyles();

    if (userMenuRoutingSelectedItem === UserMenuSelectedItem.MENU) {
        return (
            <div className={classes.profile}>
                <UserMenuNavigation isSsr={isSsr} hasHeader={false} onClose={onClose} />
            </div>
        );
    }
    const navigationPath =
        userMenuRoutingSelectedItem === UserMenuSelectedItem.ORDER
            ? UserMenuSelectedItem.ORDERS
            : userMenuRoutingSelectedItem === UserMenuSelectedItem.ADDRESS
            ? UserMenuSelectedItem.ADDRESSES
            : UserMenuSelectedItem.MENU;

    return (
        <div className={classes.profile}>
            <div className={classes.header}>
                <div className={classes.closeButton} onClick={() => action(makeUserMenuNavigateAction, navigationPath)}>
                    <Icon component={BackIcon} size={24} svgSize={24} />
                </div>
                <div className={classes.title}>
                    <Text typography={TextTypography.LEAD_ACCENT}>
                        <UserMenuHeader />
                    </Text>
                </div>
            </div>
            <div className={classes.contents}>
                <UserMenuContents />
            </div>
        </div>
    );
};

const useDialogStyles = makeStyles(theme => ({
    container: {
        background: 'none',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    close: {
        position: 'absolute',
        left: -72,
        top: 24,
        width: 48,
        height: 48,
        borderRadius: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    menu: {
        position: 'relative',
        background: '#fff',
        width: 400,
    },
}));

export const UserMenuDialog = (props: UserMenuDialogProps) => {
    const classes = useDialogStyles();
    const { onClose, isSsr } = props;
    return (
        <ErrorBoundary>
            <FullScreenDialog onClose={onClose} className={classes.container}>
                {({ onClose }) => (
                    <div className={classes.menu}>
                        <div className={classes.close} onClick={onClose}>
                            <Icon component={CloseIcon} size={16} svgSize={16} />
                        </div>
                        <UserMenu isSsr={isSsr} onClose={onClose} />
                    </div>
                )}
            </FullScreenDialog>
        </ErrorBoundary>
    );
};
