import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    checkoutCartDeliveryTypeSelector,
    checkoutCartPaymentTypeSelector,
    checkoutCartStorePaymentInfoSelector,
} from 'state/checkout/selectors';
import { Text, TextTypography } from 'components/Text/Text';

import { DeliveryType, PaymentType, StorePaymentDto } from 'types';

import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';

import { useAction } from 'core/store/store';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon/Icon';
import { makeCheckoutChosePaymentAction } from 'state/checkout/actions';
import { RadioGroupControl } from 'components/RadioGroup/RadioGroup';
import { ReactComponent as CashIcon } from 'assets/glyphs/cash.svg';
import { ReactComponent as CardsIcon } from 'assets/glyphs/cards.svg';
import { ReactComponent as OnlineIcon } from 'assets/glyphs/online.svg';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';

export interface CheckoutCartPaymentProps {
    isFullScreen?: boolean;
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    header: {
        height: 87,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    orderTextWrapper: {
        alignText: 'center',
        textAlign: 'center',
        flex: 1,
    },
    mainContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'rgba(15, 24, 37, 0.05)',
    },
    containerScroll: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(3),
    },
    icon: {
        display: 'block',
        fill: theme.palette.brandPrimary.main,
        height: theme.spacing(2),
        width: theme.spacing(2),
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
    logoIcon: {
        cursor: 'pointer',
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    iconContainer: {
        cursor: 'pointer',
        borderRadius: theme.spacing(2),
        height: theme.spacing(4),
        padding: theme.spacing(1),
        width: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    address: {
        height: 139,
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    addressLink: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    addressOpenIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    map: {
        marginRight: theme.spacing(2),
    },
    cartItem: {
        paddingTop: theme.spacing(3),
        '&:first-child': {
            paddingTop: 0,
        },
    },
    checkoutSettings: {
        display: 'flex',
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    checkoutSetting: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
        '&:first-child': {
            borderRight: `1px solid ${theme.palette.brandPrimary.accent}`,
        },
    },
    badge: {
        height: theme.spacing(2),
        width: 40,
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedSuccess.main,
    },
    badgeWithSum: {
        height: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedPrimary.primary,
    },
    checkout: {
        cursor: 'pointer',
        padding: theme.spacing(2),
    },
    paymentText: {
        color: theme.palette.fixedCritical.main,
    },
    paymentChoose: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioElement: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        backgroundColor: '#FBFEF9',
        boxShadow: '0px 0px 50px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        marginBottom: theme.spacing(3),
    },
    deliveryRadio: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addressInfo: {
        height: 'calc(100% - 80px)',
        display: 'flex',
        flexDirection: 'column',
    },
    addressElement: {
        paddingTop: theme.spacing(3),
    },
    addressText: {
        textAlign: 'center',
        display: 'flex',
        minHeight: 72,
    },
    emptyBody: {
        padding: theme.spacing(6),
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    emptyBodyMessage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        paddingBottom: theme.spacing(3),
    },
    successMessage: {
        backgroundColor: theme.palette.fixedSuccess.accent,
        borderWidth: 2,
        borderColor: theme.palette.fixedSuccess.main,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1.25),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        maxWidth: 352,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '0px 10px 20px rgba(36, 49, 67, 0.2)',
    },
    successMessageTitle: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    successButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down(1340)]: {
            flexDirection: 'column',
        },
    },
    buttonElement: {
        color: '#45CA52',
        cursor: 'pointer',
    },
    radioIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioLabel: {
        paddingLeft: theme.spacing(2),
    },
}));

function enabledPaymentType(
    deliveryType: DeliveryType,
    storePaymentInfo: StorePaymentDto[],
    paymentType: PaymentType,
): boolean {
    const paymentTypeInfo = storePaymentInfo?.find(el => el.type === paymentType);
    if (paymentTypeInfo?.enabled) {
        return deliveryType === DeliveryType.PICKUP ? paymentTypeInfo.data.atStore : paymentTypeInfo.data.atDelivery;
    } else {
        return false;
    }
}

export const CheckoutCartPayment = (props: CheckoutCartPaymentProps) => {
    const { isFullScreen, onClose } = props;
    const action = useAction();
    const { t } = i18n.useTranslation();
    const classes = useStyles({ isFullScreen });

    const storePaymentInfo = useSelector(checkoutCartStorePaymentInfoSelector);
    const deliveryType: DeliveryType = useSelector(checkoutCartDeliveryTypeSelector);
    const paymentType: PaymentType = useSelector(checkoutCartPaymentTypeSelector);

    const paymentItems = [
        {
            value: PaymentType.CASH,
            label: t('feed:checkout:payment:CASH'),
            icon: CashIcon,
            enabled: enabledPaymentType(deliveryType, storePaymentInfo, PaymentType.CASH),
        },
        {
            value: PaymentType.CARD,
            label: t('feed:checkout:payment:CARD'),
            icon: CardsIcon,
            enabled: enabledPaymentType(deliveryType, storePaymentInfo, PaymentType.CARD),
        },
        {
            value: PaymentType.OLPAY,
            label: t('feed:checkout:payment:OLPAY'),
            icon: OnlineIcon,
            enabled: storePaymentInfo?.find(el => el.type === PaymentType.OLPAY)?.enabled,
        },
    ].filter(paymentMethod => paymentMethod.enabled);

    return (
        <ErrorBoundary>
            <Box className={classes.mainContainer}>
                <div className={classes.header}>
                    <div className={classes.iconContainer} onClick={onClose}>
                        <Icon component={BackIcon} size={16} svgSize={16} className={classes.icon} />
                    </div>
                    <div className={classes.orderTextWrapper}>
                        <Box textAlign={'center'}>
                            <Text typography={TextTypography.LEAD_ACCENT}>{t('feed:checkout.payment.title')}</Text>
                        </Box>
                    </div>
                    <div className={classes.iconContainer} />
                </div>
                <div className={classes.containerScroll}>
                    <Text typography={TextTypography.PARA}>
                        {paymentItems?.length == 0
                            ? t('feed:checkout.payment.nothingToChoose')
                            : t('feed:checkout.payment.choose')}
                    </Text>
                    <RadioGroupControl
                        defaultValue={paymentType}
                        name={'paymentType'}
                        items={paymentItems}
                        onChange={value => action(makeCheckoutChosePaymentAction, value.target.value)}
                    />
                </div>
                <div>
                    <div className={classes.checkout}>
                        <LargeButton color={LargeButtonColor.SUCCESS} onClick={onClose}>
                            {t('feed:checkout.continue')}
                        </LargeButton>
                    </div>
                </div>
                <CustomerMenu navigationPath={CustomerMenuSelectedItem.CHECKOUT} />
            </Box>
        </ErrorBoundary>
    );
};
