import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Text, TextTypography } from 'components/Text/Text';
import { Icon } from 'components/Icon/Icon';
import i18n from '../../../../i18n';
import { useAction } from 'core/store/store';
import { Currency } from 'components/Currency/Currency';
import { makeCheckoutChangeProductQuantityAction, makeCheckoutRemoveFromCartAction } from 'state/checkout/actions';
import { ReactComponent as AddCountIcon } from 'assets/icons/additional/add-count-icon.svg';
import { ReactComponent as RemoveCountIcon } from 'assets/icons/additional/remove-count-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';
import { ReactComponent as TimesIcon } from 'assets/icons/additional/red-trash.svg';
import DefaultImageStore from 'images/DefaultImageStore.webp';
import { OrderResponseProduct } from 'state/order/types';
import { SupportedCountryCodes } from '../../../types';

export interface CartItemProps {
    product: OrderResponseProduct;
    isoCode: SupportedCountryCodes;
}

const useStyles = makeStyles(theme => ({
    productCardPhoto: {
        backgroundColor: theme.palette.fixedPrimary.lightest,
        borderRadius: 20,
        height: 72,
        minWidth: 72,
        width: 72,
        marginRight: 16,
    },
    defaultImage: {
        backgroundImage: `url(${DefaultImageStore})`,
        borderRadius: 20,
        width: 72,
        minWidth: 72,
        height: 72,
        backgroundSize: 'cover',
        marginRight: 16,
        backgroundPosition: 'center',
    },
    container: {
        paddingBottom: theme.spacing(2),
        position: 'relative',
        display: 'flex',
    },
    controls: {
        marginTop: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttons: {
        width: theme.spacing(10),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    quantityButton: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        width: 24,
    },
    quantity: {
        margin: '0 8px',
    },
    info: {
        whiteSpace: 'nowrap',
        marginLeft: 16,
    },
    trashIcon: {
        top: 8,
        left: 8,
        width: 24,
        height: 24,
        borderRadius: '100%',
        backgroundColor: '#fff',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    contents: {
        width: '100%',
    },
    optionRow: {
        display: 'flex',
    },
    optionRowName: {
        flex: 1,
        whiteSpace: 'pre-line',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
    title: {
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre-line',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
}));

export const CartItem = (props: CartItemProps) => {
    const { t } = i18n.useTranslation();
    const action = useAction();
    const { product, isoCode } = props;
    const { name, totalPrice, unitQuantity, unitType, defaultImage, orderedOptions, quantity } = product;
    const imageId = defaultImage?.imageId;
    const classes = useStyles();

    const handleQuantity = useCallback(
        value => {
            if (value === 0) {
                action(makeCheckoutRemoveFromCartAction, product);
                return;
            }
            action(makeCheckoutChangeProductQuantityAction, product, value);
        },
        [product, action],
    );

    const handleRemoveFromCart = useCallback(() => {
        action(makeCheckoutRemoveFromCartAction, product);
    }, [action, product]);

    return (
        <div className={classes.container}>
            <div className={classes.trashIcon} onClick={handleRemoveFromCart}>
                <Icon component={CloseIcon} size={16} svgSize={16} />
            </div>
            {imageId ? (
                <img
                    className={classes.productCardPhoto}
                    src={`/api/images/${imageId}?img_v=${180}`}
                    alt={imageId}
                    key={imageId}
                />
            ) : (
                <div className={classes.defaultImage} />
            )}
            <div className={classes.contents}>
                <Box className={classes.title}>
                    <Text typography={TextTypography.BODY_ACCENT}>{name}</Text>
                </Box>
                <div>
                    {orderedOptions.map(option => (
                        <div key={option.optionId} className={classes.optionRow}>
                            <Text className={classes.optionRowName} typography={TextTypography.BODY}>
                                {option?.name}
                            </Text>
                            <Text typography={TextTypography.BODY_ACCENT}>
                                <Currency isoCode={isoCode}>{option.price}</Currency>
                            </Text>
                        </div>
                    ))}
                </div>
                <div className={classes.controls}>
                    <div className={classes.buttons}>
                        <div className={classes.quantityButton} onClick={() => handleQuantity(quantity - 1)}>
                            <Icon
                                component={quantity === 1 ? TimesIcon : RemoveCountIcon}
                                size={24}
                                svgSize={quantity === 1 ? 32 : 24}
                            />
                        </div>
                        <Text className={classes.quantity} typography={TextTypography.BODY_ACCENT}>
                            {quantity}
                        </Text>
                        <div className={classes.quantityButton} onClick={() => handleQuantity(quantity + 1)}>
                            <Icon component={AddCountIcon} size={24} svgSize={24} />
                        </div>
                        <div className={classes.info}>
                            ×&nbsp;&nbsp;{`${unitQuantity} ${t('common:enums.UNIT_TYPE.' + unitType)}`}
                        </div>
                    </div>
                    <Text typography={TextTypography.BODY_ACCENT}>
                        <Currency isoCode={isoCode}>{totalPrice}</Currency>
                    </Text>
                </div>
            </div>
        </div>
    );
};
