import React from 'react';
import { useSelector } from 'react-redux';

import { checkoutPaymentStatusSelector } from 'state/checkout/selectors';
import { OnlinePaymentStatus } from 'state/checkout/reducer';

import { CircularProgressPage } from 'components/CircularProgressPage/CircularProgressPage';
import { CheckoutCart } from './CheckoutCart';
import { CheckoutPaymentFailure } from './CheckoutPaymentFailure';

import { CheckoutPaymentForm } from './CheckoutPaymentForm';

export interface CheckoutProps {
    isFullScreen: boolean;
}

export const Checkout = (props: CheckoutProps) => {
    const { isFullScreen } = props;
    const paymentStatus = useSelector(checkoutPaymentStatusSelector);
    switch (paymentStatus) {
        case OnlinePaymentStatus.PENDING:
            return <CircularProgressPage />;
        case OnlinePaymentStatus.FAILURE:
            return <CheckoutPaymentFailure />;
        case OnlinePaymentStatus.FORM:
            return <CheckoutPaymentForm />;
        default:
            return <CheckoutCart isFullScreen={isFullScreen} />;
    }
};
