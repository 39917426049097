import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch as MaterialSwitch } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';

export interface SwitchControlProps {
    label: string;
    // Optional:
    className?: string;
    disabled?: boolean;
    value?: boolean;
    name?: string;
    onChange?: (value: boolean) => void;
}

const labelStyles: any = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        // marginTop: 8,
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        width: 40,
        height: 26,
        padding: 0,
        // margin: theme.spacing(0, 1),
        marginLeft: 8,
        marginRight: 16,
    },
    switchBase: {
        padding: 1,
        '&$disabled': {
            color: theme.palette.fixedWhite.primary,
            '& + $track': {
                backgroundColor: theme.palette.fixedPrimary.lighter,
                opacity: 1,
                border: 'none',
            },
        },
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.fixedWhite.primary,
            '& + $track': {
                backgroundColor: theme.palette.fixedSuccess.main,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.fixedSuccess.main,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 22,
        height: 23,
    },
    track: {
        borderRadius: 13,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    disabled: {},
}));

export const SwitchControl: FC<SwitchControlProps> = props => {
    const { className } = props;
    const { focusVisible, ...classesRest } = useStyles();
    const labelClasses = labelStyles();
    return (
        <FormControlLabel
            classes={labelClasses}
            className={className}
            control={
                <MaterialSwitch
                    focusVisibleClassName={focusVisible}
                    disableRipple
                    disabled={props.disabled}
                    classes={classesRest}
                    checked={props.value}
                    name={props.name}
                    onChange={e => props.onChange(e.target.checked)}
                />
            }
            label={props.label}
        />
    );
};

export interface SwitchControlFieldOwnProps {
    label: string;
}

export type SwitchControlFieldProps = SwitchControlFieldOwnProps & WrappedFieldProps;

export const SwitchControlField = (props: SwitchControlFieldProps) => {
    const { input, label, ...rest } = props;
    return <SwitchControl label={label} {...rest} {...input} />;
};
