import React from 'react';
import { useSelector } from 'react-redux';

import { useAction } from 'core/store/store';

import {
    makeCheckoutHideSilentLoginDialogAction,
    makeCheckoutSilentLoginSubmitAction,
    makeSubmitCheckoutSecondStepAction,
} from 'state/checkout/actions';
import {
    checkoutCartDeliveryTypeSelector,
    checkoutIsoCodeSelector,
    checkoutShowSilentLoginDialogSelector,
    checkoutTotalPriceSelector,
} from 'state/checkout/selectors';
import { feedAddressSelector } from 'state/feed/selectors';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { SilentLoginDialog } from './SilentLoginDialog';

import { isUIEnabledSelector } from 'state/app/selectors';
import { UserRole } from 'types';
import { formatCurrency } from '../../core/utils/utils';
import i18n from '../../../i18n';

export const CheckoutButton = () => {
    const totalPrice = useSelector(checkoutTotalPriceSelector);
    const deliveryType = useSelector(checkoutCartDeliveryTypeSelector);
    const action = useAction();
    const address = useSelector(feedAddressSelector);
    const showSilentLoginDialog = useSelector(checkoutShowSilentLoginDialogSelector);
    const isUIEnabled = useSelector(isUIEnabledSelector);
    const isoCode = useSelector(checkoutIsoCodeSelector);
    const { t } = i18n.useTranslation();

    return (
        <ErrorBoundary>
            {showSilentLoginDialog && (
                <SilentLoginDialog
                    onClose={() => {
                        action(makeCheckoutHideSilentLoginDialogAction);
                    }}
                    onSuccess={(jwt, username) => {
                        action(makeCheckoutSilentLoginSubmitAction, jwt, username, UserRole.CUSTOMER);
                    }}
                />
            )}
            <LargeButton
                color={totalPrice && deliveryType ? LargeButtonColor.SUCCESS : LargeButtonColor.LIGHT}
                disabled={!(totalPrice && deliveryType && isUIEnabled)}
                onClick={() => {
                    action(makeSubmitCheckoutSecondStepAction);
                }}
            >
                {address
                    ? totalPrice
                        ? t('feed:checkout.orderWithPrice', { price: formatCurrency(totalPrice, isoCode) })
                        : t('feed:checkout.order')
                    : t('feed:checkout.continue')}
            </LargeButton>
        </ErrorBoundary>
    );
};
