import React, { FC } from 'react';

import { DateTime } from '../DateTime/DateTime';
import { OrderActions } from './actions/OrderActions';
import { OrdersStatusHistoryRow } from './OrdersStatusHistoryRow';

import { OrderChangeStatusIntent, OrderStatus, UserRole } from 'types';
import { OrderResponseHistoryItem } from 'state/order/types';

export interface OrdersStatusProgressionProps {
    id: string;
    statusHistory: OrderResponseHistoryItem[];
    userRole: UserRole;
    onIntent: (intent: OrderChangeStatusIntent) => void;
    isPending: boolean;
}

const isOrderDeclined = (order: { userNotes?: string }) => {
    return order.userNotes && order.userNotes.startsWith('Declined');
};

export const OrdersStatusHistory: FC<OrdersStatusProgressionProps> = props => {
    const items = (props.statusHistory || []).filter(item => item.orderStatus !== OrderStatus.UNPAID);
    const previousItems = items.slice(0, items.length - 1);
    const currentItem = items[items.length - 1];
    const isCancelled = currentItem.orderStatus === OrderStatus.CLOSED && isOrderDeclined(currentItem);
    return (
        <div>
            {previousItems.map((item, i) => {
                return (
                    <OrdersStatusHistoryRow complete={true} key={i} status={item.orderStatus} isCancelled={isCancelled}>
                        <DateTime>{item.timestamp}</DateTime>
                    </OrdersStatusHistoryRow>
                );
            })}
            {currentItem && (
                <OrdersStatusHistoryRow complete={false} status={currentItem.orderStatus} isCancelled={isCancelled}>
                    <OrderActions
                        status={currentItem.orderStatus}
                        userRole={props.userRole}
                        onIntent={props.onIntent}
                        isPending={props.isPending}
                    />
                </OrdersStatusHistoryRow>
            )}
        </div>
    );
};
