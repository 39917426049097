import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import css from './CheckoutCartOrders.module.css';

import { feedAddressCommentSelector, feedAddressSelector } from 'state/feed/selectors';
import {
    checkoutCardStorePromosSelector,
    checkoutCartDeliveryTypeSelector,
    checkoutCartSelector,
    checkoutCartStoreInfoSelector,
    checkoutIsoCodeSelector,
    checkoutTotalPriceSelector,
} from 'state/checkout/selectors';
import { Text, TextTypography } from 'components/Text/Text';
import { CartItem } from './CartItem/CartItem';

import { ReactComponent as TrashIcon } from 'assets/icons/main-16/trash--16.svg';
import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';
import { ReactComponent as NextIcon } from 'assets/icons/main-16/chevron-right--16.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/status/check-circle--16.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/status/exclamation-triangle--16.svg';

import { useAction } from 'core/store/store';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon/Icon';
import { makeCheckoutCloseCartAction, makeCheckoutRemoveFullCartAction } from 'state/checkout/actions';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import { T } from 'components/T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { isUIEnabledSelector } from 'state/app/selectors';
import { hasLocalStorageItem, LocalStorageKey } from 'core/storage/storage';
import { DeliveryType } from 'types';
import { makeSnackbarErrorAction } from 'state/snackbar/actions';
import { formatCurrency } from '../../core/utils/utils';

export interface CheckoutCartOrdersProps {
    isFullScreen?: boolean;
    onOpenAddressPage: () => void;
    onOpenDeliveryPage: () => void;
    onOpenPaymentPage: () => void;
    onOpenPromosPage: () => void;
    onOpenPrevOrderPage: () => void;
}

const useStyles = makeStyles(theme => ({
    header: {
        height: 87,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    orderTextWrapper: {
        alignText: 'center',
        textAlign: 'center',
        flex: 1,
    },
    mainContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'rgba(15, 24, 37, 0.05)',
    },
    containerScroll: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(3),
    },
    icon: {
        display: 'block',
        fill: theme.palette.brandPrimary.main,
        height: theme.spacing(2),
        width: theme.spacing(2),
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
    logoIcon: {
        cursor: 'pointer',
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    iconContainer: {
        cursor: (props: { isFullScreen: boolean }) => (!props.isFullScreen ? 'default' : 'pointer'),
        borderRadius: theme.spacing(2),
        height: theme.spacing(4),
        padding: theme.spacing(1),
        width: theme.spacing(4),
    },
    address: {
        height: 139,
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    addressLink: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    addressOpenIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    map: {
        marginRight: theme.spacing(2),
    },
    cartItem: {
        paddingTop: theme.spacing(3),
        '&:first-child': {
            paddingTop: 0,
        },
    },
    checkoutSettings: {
        display: 'flex',
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    checkoutSetting: {
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        cursor: 'pointer',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderLeft: `1px solid ${theme.palette.brandPrimary.accent}`,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
        '&:first-child': {
            borderLeft: 'none',
        },
    },
    checkoutSettingText: {
        flex: 1,
    },
    badge: {
        height: theme.spacing(2),
        width: 40,
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedSuccess.main,
    },
    badgeWithSum: {
        height: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.fixedPrimary.primary,
    },
    checkout: {
        cursor: 'pointer',
        padding: theme.spacing(2),
    },
    paymentText: {
        color: theme.palette.fixedCritical.main,
    },
    paymentChoose: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioElement: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        backgroundColor: '#FBFEF9',
        boxShadow: '0px 0px 50px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        marginBottom: theme.spacing(3),
    },
    deliveryRadio: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addressInfo: {
        height: 'calc(100% - 80px)',
        display: 'flex',
        flexDirection: 'column',
    },
    addressElement: {
        paddingTop: theme.spacing(3),
    },
    addressText: {
        textAlign: 'center',
        display: 'flex',
        minHeight: 72,
    },
    emptyBody: {
        padding: theme.spacing(6),
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    emptyBodyMessage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        paddingBottom: theme.spacing(3),
    },
    successMessage: {
        backgroundColor: theme.palette.fixedSuccess.accent,
        borderWidth: 2,
        borderColor: theme.palette.fixedSuccess.main,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1.25),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        maxWidth: 352,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '0px 10px 20px rgba(36, 49, 67, 0.2)',
    },
    successMessageTitle: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    successButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down(1340)]: {
            flexDirection: 'column',
        },
    },
    buttonElement: {
        color: '#45CA52',
        cursor: 'pointer',
    },
    radioIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioLabel: {
        paddingLeft: theme.spacing(2),
    },
    promoStatusIcon: {
        display: 'inline-block',
        height: 16,
        marginRight: 12,
        position: 'relative',
        top: -1,
        width: 16,
        verticalAlign: 'middle',
    },
    name: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

export const CheckoutCartOrders = (props: CheckoutCartOrdersProps) => {
    const {
        isFullScreen,
        onOpenAddressPage,
        onOpenDeliveryPage,
        onOpenPaymentPage,
        onOpenPromosPage,
        onOpenPrevOrderPage,
    } = props;

    const address = useSelector(feedAddressSelector);
    const comment = useSelector(feedAddressCommentSelector);
    const storeInfo = useSelector(checkoutCartStoreInfoSelector);
    const cart = useSelector(checkoutCartSelector);
    const isoCode = useSelector(checkoutIsoCodeSelector);
    const promos = useSelector(checkoutCardStorePromosSelector);

    const [highlightAddress, setHighlightAddress] = useState(false);

    const action = useAction();
    const { t } = i18n.useTranslation();

    const classes = useStyles({ isFullScreen });

    const activePromoId = cart.selectedPromo?.promo.promoId;

    const onBackButtonClick = useCallback(() => {
        action(makeCheckoutCloseCartAction);
    }, [action]);

    const hasAddressChanged = useMemo(() => {
        return hasLocalStorageItem(LocalStorageKey.ADDRESS_CHANGED_BY_USER);
    }, [address]);

    const handleSubmit = useCallback(() => {
        if (hasAddressChanged) {
            onOpenPrevOrderPage();
        } else {
            action(makeSnackbarErrorAction, t('feed:checkout.address.clarify'));
            setHighlightAddress(true);
        }
    }, [onOpenPrevOrderPage, hasAddressChanged]);

    const selectedPromo = activePromoId ? promos.find(promo => promo.promoId === activePromoId) : null;

    const promoText =
        cart.selectedPromo?.promoApplied && selectedPromo
            ? selectedPromo.description
            : cart.selectedPromo?.promoNotification;

    const totalPrice = useSelector(checkoutTotalPriceSelector);
    const deliveryType: DeliveryType = useSelector(checkoutCartDeliveryTypeSelector);
    const isUIEnabled = useSelector(isUIEnabledSelector);

    return (
        <ErrorBoundary>
            <Box className={classes.mainContainer}>
                <div className={classes.header}>
                    <div className={classes.iconContainer}>
                        {isFullScreen && (
                            <div onClick={onBackButtonClick}>
                                <Icon component={BackIcon} size={16} svgSize={16} className={classes.icon} />
                            </div>
                        )}
                    </div>
                    <div className={classes.orderTextWrapper}>
                        <Box textAlign={'center'}>
                            <Text typography={TextTypography.BODY}>{t('feed:checkout:myOrder')}</Text>
                        </Box>
                        {storeInfo?.name && (
                            <Box textAlign={'center'} className={classes.name}>
                                <Text typography={TextTypography.LEAD_ACCENT}>{storeInfo.name}</Text>
                            </Box>
                        )}
                    </div>
                    <div
                        className={classes.iconContainer}
                        onClick={() => {
                            action(makeCheckoutRemoveFullCartAction);
                        }}
                    >
                        <Icon component={TrashIcon} size={16} svgSize={16} color={'#243143'} />
                    </div>
                </div>
                <div className={classes.containerScroll}>
                    {cart.orderedProducts?.map((product, i) => (
                        <div key={i} className={classes.cartItem}>
                            <CartItem product={product} isoCode={isoCode} />
                        </div>
                    ))}
                </div>

                {promos.length > 0 && (
                    <div className={classes.checkoutSettings}>
                        <div className={classes.checkoutSetting} onClick={onOpenPromosPage}>
                            {selectedPromo ? (
                                <Fragment>
                                    <div>
                                        {cart.selectedPromo.promoApplied ? (
                                            <Icon
                                                className={classes.promoStatusIcon}
                                                component={CheckIcon}
                                                svgSize={16}
                                            />
                                        ) : (
                                            <Icon
                                                className={classes.promoStatusIcon}
                                                component={WarningIcon}
                                                svgSize={16}
                                            />
                                        )}
                                    </div>
                                    <div className={classes.checkoutSettingText}>{promoText}</div>
                                </Fragment>
                            ) : (
                                <div className={classes.checkoutSettingText}>
                                    <Text typography={TextTypography.BODY_ACCENT}>
                                        {t('feed:checkout.address.stock')}
                                    </Text>
                                </div>
                            )}
                            <div className={classes.addressOpenIcon}>
                                <Icon component={NextIcon} size={16} svgSize={16} className={classes.icon} />
                            </div>
                        </div>
                    </div>
                )}

                <div className={classes.addressLink} onClick={onOpenAddressPage}>
                    <div>
                        <div>
                            <Text typography={TextTypography.CAPTION}>{t('feed:checkout.myAddress')}</Text>
                        </div>
                        <div>
                            <Text
                                className={highlightAddress && css.text_color_red}
                                typography={TextTypography.BODY_ACCENT}
                            >
                                {hasAddressChanged ? address + ',' + comment : t('feed:checkout.address.clarify')}
                            </Text>
                        </div>
                    </div>
                    <div className={classes.addressOpenIcon}>
                        <Icon component={NextIcon} size={16} svgSize={16} className={classes.icon} />
                    </div>
                </div>
                <div className={classes.checkoutSettings}>
                    <div className={classes.checkoutSetting} onClick={onOpenDeliveryPage}>
                        <div className={classes.checkoutSettingText}>
                            <div>
                                <Text typography={TextTypography.CAPTION}>{t('feed:checkout.shipping.title')}</Text>
                            </div>
                            <div>
                                <Text typography={TextTypography.BODY_ACCENT}>
                                    <T enum={TranslatableEnum.DELIVERY_TYPE}>{cart?.deliveryInfo?.deliveryType}</T>
                                </Text>
                            </div>
                        </div>
                        <div className={classes.addressOpenIcon}>
                            <Icon component={NextIcon} size={16} svgSize={16} className={classes.icon} />
                        </div>
                    </div>
                    <div className={classes.checkoutSetting} onClick={onOpenPaymentPage}>
                        <div className={classes.checkoutSettingText}>
                            <div>
                                <Text typography={TextTypography.CAPTION}>{t('feed:checkout.payment.title')}</Text>
                            </div>
                            <div>
                                <Text typography={TextTypography.BODY_ACCENT}>
                                    <T enum={TranslatableEnum.PAYMENT_TYPE}>{cart?.paymentInfo?.paymentType}</T>
                                </Text>
                            </div>
                        </div>
                        <div className={classes.addressOpenIcon}>
                            <Icon component={NextIcon} size={16} svgSize={16} className={classes.icon} />
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={classes.checkout}
                        style={{ backgroundColor: !storeInfo?.storeDeliveries && 'rgba(15, 24, 37, 0.05)' }}
                    >
                        <LargeButton
                            color={totalPrice && deliveryType ? LargeButtonColor.SUCCESS : LargeButtonColor.LIGHT}
                            disabled={!(totalPrice && deliveryType && isUIEnabled)}
                            onClick={handleSubmit}
                        >
                            {address
                                ? totalPrice
                                    ? t('feed:checkout.orderWithPrice', { price: formatCurrency(totalPrice, isoCode) })
                                    : t('feed:checkout.order')
                                : t('feed:checkout.continue')}
                        </LargeButton>
                    </div>
                </div>
                {!isFullScreen && <CustomerMenu navigationPath={CustomerMenuSelectedItem.CHECKOUT} />}
            </Box>
        </ErrorBoundary>
    );
};
