import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { useAction, useActionDispatchOnMount } from 'core/store/store';

import { makeUserMenuAddressesFetchAction, makeUserMenuNavigateAction } from 'state/userMenu/actions';
import { AddressesList } from 'components/AddressesList/AddressesList';
import { userAddressesStoreSelector } from 'state/userMenu/selector';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as AddIcon } from '../../assets/icons/main-16/add--16.svg';
import { Icon } from 'components/Icon/Icon';
import i18n from '../../../i18n';
import { Text, TextTypography } from 'components/Text/Text';
import { UserMenuSelectedItem } from 'state/userMenu/reducer';

export interface AddressCardProps {
    onAddressClick: (addressId: string) => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    list: {
        overflow: 'auto',
    },
    footer: {
        cursor: 'pointer',
        padding: theme.spacing(3),
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
}));

export const AddressesCard = (props: AddressCardProps) => {
    const { t } = i18n.useTranslation();
    const action = useAction();
    useActionDispatchOnMount(true, makeUserMenuAddressesFetchAction);
    const classes = useStyles();
    const addressItems = useSelector(userAddressesStoreSelector);
    return (
        <div className={classes.container}>
            <Box className={classes.list}>
                <AddressesList addresses={addressItems} onAddressClick={props.onAddressClick} />
            </Box>
            <div
                className={classes.footer}
                onClick={() => action(makeUserMenuNavigateAction, UserMenuSelectedItem.ADDRESS)}
            >
                <div className={classes.icon}>
                    <Icon component={AddIcon} size={16} svgSize={16} />
                </div>
                <Text typography={TextTypography.PARA_ACCENT}>{t('common:userMenu:addresses:add')}</Text>
            </div>
        </div>
    );
};
