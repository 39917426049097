import React, { ChangeEvent, MouseEvent } from 'react';
import { RadioGroup, FormControlLabel, Popover } from '@material-ui/core';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { appConfig } from '../../config/app';
import i18n from '../../../i18n';
import browserCookies from 'browser-cookies';
import classes from './style.module.css';
import { ReactComponent as WorldIcon } from 'assets/world.svg';

export const SelectRegionControl = () => {
    const { t } = i18n.useTranslation();
    let value: string;
    const radioElements = appConfig.getRegions;
    if (!(typeof window === 'undefined')) {
        value = browserCookies.get('country');
        if (radioElements.findIndex(e => e === value) === -1) {
            value = radioElements[0];
        }
    }

    const handleLanguageChange = React.useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            browserCookies.set('country', e.target.value);
            handleClose();
            window.location.reload();
        },
        [browserCookies],
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div className={classes.button} aria-describedby={id} onClick={handleClick}>
                <div className={classes.worldIcon}>
                    <WorldIcon />
                </div>
                {t(`common:regions.${value}`)}
                <div className={classes.triangle} />
            </div>
            <Popover className={classes.popover} id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                <RadioGroup
                    className={classes.popover}
                    name={'language'}
                    style={{ width: '100%' }}
                    value={value}
                    onChange={handleLanguageChange}
                >
                    {radioElements.map(el => (
                        <FormControlLabel
                            key={el}
                            value={el}
                            control={<RadioButton />}
                            label={t(`common:regions.${el}`)}
                        />
                    ))}
                </RadioGroup>
            </Popover>
        </>
    );
};
