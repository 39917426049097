import { makeStyles } from '@material-ui/core';
import { RoutePath } from 'RoutePath';
import { ReactComponent as CompassIcon } from 'assets/icons/main-24/compass--24.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/main-24/menu-o--24.svg';
import { ReactComponent as OrdersIcon } from 'assets/icons/main-24/orders--24.svg';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/main-24/shopping-cart--24.svg';
import classNames from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { LayoutFixedItem } from 'components/Layout/LayoutFixedItem';
import { Text, TextTypography } from 'components/Text/Text';
import { useAction } from 'core/store/store';
import React from 'react';
import i18n from '../../../i18n';
import { useSelector } from 'react-redux';
import { authRoleSelector } from 'state/auth/selectors';
import { makeUserMenuHideAction, makeUserMenuNavigateAction, makeUserMenuShowAction } from 'state/userMenu/actions';
import { UserMenuSelectedItem } from 'state/userMenu/reducer';
import { makeCheckoutCloseCartAction, makeCheckoutOpenCartAction } from 'state/checkout/actions';

export enum CustomerMenuSelectedItem {
    MAIN_PAGE,
    ORDERS,
    CHECKOUT,
    MENU,
}

const useStyles = makeStyles(theme => ({
    menuContainer: {
        height: 80,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: '#fbfef9',
        display: 'flex',
        justifyContent: 'space-evenly',
        position: 'sticky',
        width: '100%',
        bottom: 0,
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
        zIndex: 200,
        [theme.breakpoints.up(768)]: {
            display: 'none',
        },
        [theme.breakpoints.between(640, 768)]: {
            padding: `0 ${theme.spacing(9)}px`,
        },
        [theme.breakpoints.between(480, 640)]: {
            justifyContent: 'space-around',
            padding: `0 ${theme.spacing(2.5)}px`,
        },
        [theme.breakpoints.down(480)]: {
            height: 64,
        },
    },
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            '& path, rect': {
                fill: 'rgba(36, 49, 67, 0.8)',
            },
        },
        '& span': {
            color: 'rgba(36, 49, 67, 0.8)',
        },
    },
    activeMenuItem: {
        '& svg': {
            '& path, rect': {
                fill: theme.palette.fixedArrowGreen.main,
            },
        },
        '& span': {
            color: theme.palette.fixedArrowGreen.main,
        },
    },
    menuIcon: {
        marginBottom: 8,
    },
    disabledMenuItem: {
        '& svg': {
            '& path, rect': {
                fill: theme.palette.brandPrimary.lightest,
            },
        },
        '& span': {
            color: theme.palette.brandPrimary.lightest,
        },
    },
}));

export const CustomerMenu = (props: { navigationPath?: CustomerMenuSelectedItem; customStyles?: string }) => {
    const action = useAction();
    const { t } = i18n.useTranslation();
    const currentRole = useSelector(authRoleSelector);
    const classes = useStyles();
    const menuItems = [
        {
            label: t('common:userMenu.mobile.main'),
            icon: CompassIcon,
            key: CustomerMenuSelectedItem.MAIN_PAGE,
            onClick: () => {
                // checking the open menu (menu doesn't change the path)
                if (window.location.pathname === RoutePath.FEED) {
                    action(makeUserMenuHideAction);
                    action(makeCheckoutCloseCartAction);
                } else {
                    window.location.assign(RoutePath.FEED);
                }
            },
        },
        {
            label: t('common:userMenu.mobile.orders'),
            icon: OrdersIcon,
            key: CustomerMenuSelectedItem.ORDERS,
            onClick: () => {
                if (!!currentRole) {
                    action(makeCheckoutCloseCartAction);
                    action(makeUserMenuNavigateAction, UserMenuSelectedItem.ORDERS);
                }
            },
            disabled: !currentRole,
        },
        {
            label: t('common:userMenu.mobile.cart'),
            icon: ShoppingCartIcon,
            key: CustomerMenuSelectedItem.CHECKOUT,
            onClick: () => {
                action(makeUserMenuHideAction);
                action(makeCheckoutOpenCartAction);
            },
        },
        {
            label: t('common:userMenu.mobile.menu'),
            icon: MenuIcon,
            key: CustomerMenuSelectedItem.MENU,
            onClick: () => {
                switch (props.navigationPath) {
                    case CustomerMenuSelectedItem.ORDERS:
                    case CustomerMenuSelectedItem.MENU:
                        action(makeUserMenuNavigateAction, UserMenuSelectedItem.MENU);
                        break;
                    default:
                        action(makeUserMenuShowAction);
                        action(makeUserMenuNavigateAction, UserMenuSelectedItem.MENU);
                        break;
                }
            },
        },
    ];

    return (
        <LayoutFixedItem className={classNames(props.customStyles, classes.menuContainer)}>
            {menuItems.map(menuItem => (
                <div
                    className={classNames(
                        classes.menuItemContainer,
                        props.navigationPath === menuItem.key && classes.activeMenuItem,
                        menuItem.disabled && classes.disabledMenuItem,
                    )}
                    key={menuItem.key}
                    onClick={menuItem.onClick}
                >
                    <Icon className={classes.menuIcon} component={menuItem.icon} size={24} svgSize={24} />
                    <Text typography={TextTypography.CAPTION_ACCENT}>{menuItem.label}</Text>
                </div>
            ))}
        </LayoutFixedItem>
    );
};
