import { useMounted } from './useMounted';

export const ensureScriptIsAdded = (src: string) => {
    if (typeof window === 'undefined') {
        return;
    }

    return new Promise(resolve => {
        let script: HTMLScriptElement = document.querySelector(`script[src="${src}"]`);
        if (script) {
            resolve();
        } else {
            script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = resolve;
            document.body.appendChild(script);
        }
    });
};
