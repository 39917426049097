import React from 'react';
import i18n from '../../../i18n';
import { useSelector } from 'react-redux';

import { userMenuRoutingSelectedItemSelector } from 'state/userMenu/selector';
import { UserMenuSelectedItem } from 'state/userMenu/reducer';

export interface UserMenuContentsProps {
    onClose: () => void;
}

export const UserMenuHeader = () => {
    const { t } = i18n.useTranslation();
    const userMenuRoutingSelectedItem = useSelector(userMenuRoutingSelectedItemSelector);
    switch (userMenuRoutingSelectedItem) {
        case UserMenuSelectedItem.PROFILE:
            return <span>{t('common:userMenu.profile.title')}</span>;
        case UserMenuSelectedItem.ORDERS:
            return <span>{t('common:userMenu.orders.title')}</span>;
        case UserMenuSelectedItem.ORDER:
            return <span>{t('common:userMenu.order.title')}</span>;
        case UserMenuSelectedItem.ADDRESSES:
            return <span>{t('common:userMenu.addresses.title')}</span>;
        case UserMenuSelectedItem.ADDRESS:
            return <span>{t('common:userMenu.address.title')}</span>;
    }
};
