import React, { useCallback, useEffect } from 'react';
import i18n from '../../../i18n';
import { useSelector } from 'react-redux';
import { useAction } from 'core/store/store';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import { useFormError } from 'core/form/form';

import { isUIEnabledSelector } from 'state/app/selectors';

import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { LargeButton } from 'components/LargeButton/LargeButton';
import { ReactComponent as ResetPasswordIcon } from 'assets/icons/main-16/internet-access--16.svg';
import { Text, TextTypography } from 'components/Text/Text';

import { makeUserMenuHideAction } from 'state/userMenu/actions';

import { Form } from 'forms/types';
import { TextControlField } from 'components/TextControl/TextControl';
import { Footer } from 'components/Footer/Footer';
import { RoutePath } from 'RoutePath';
import { Icon } from 'components/Icon/Icon';
import { userMenuProfileSelector } from 'state/userMenu/selector';
import { authPhoneNumberSelector } from 'state/auth/selectors';
import { MobilePhoneControl } from 'components/MobilePhoneControl/MobilePhoneControl';
import { KeyboardDatePickerControlField } from 'components/KeyboardDatePickerControl/KeyboardDatePickerControl';

export interface ProfileFormData {
    name: string;
    birthDate: string;
    email: string;
}

const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-ZА-ЯЁ0-9.-]+\.[A-ZА-ЯЁ]+$/i;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'calc(100% - 70px)',
    },
    controlFields: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    field: {
        marginTop: theme.spacing(3),
    },
    phone: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRole: {
        marginBottom: theme.spacing(4),
    },
    saveButton: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    reset: {
        cursor: 'pointer',
        padding: theme.spacing(3),
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
}));

export const USER_DEFAULT_DATA: ProfileFormData = {
    name: '',
    birthDate: null,
    email: '',
};

interface ProfileFormProps {
    isVendorProfile: boolean;
}

export const ProfileForm = reduxForm<ProfileFormData, ProfileFormProps>({
    form: Form.PROFILE_FORM,
    initialValues: USER_DEFAULT_DATA,
})(props => {
    const { handleSubmit, initialize, isVendorProfile } = props;
    const isUIEnabled = useSelector(isUIEnabledSelector);
    const { t } = i18n.useTranslation();
    const action = useAction();
    const error = useFormError(Form.PROFILE_FORM);
    const classes = useStyles();
    const userMenuFormData = useSelector(userMenuProfileSelector);
    const phone = useSelector(authPhoneNumberSelector);

    const emailControlValidator = useCallback(
        (v: string) => {
            if (isVendorProfile && !v) {
                return t('common:validation.required');
            }
            if (v?.length > 0 && !EMAIL_REGEXP.test(v)) {
                return t('onboarding:settings.yandexCheckoutSetupCard.errors.emailValidation');
            }
        },
        [isVendorProfile, t],
    );

    useEffect(() => {
        initialize({
            name: userMenuFormData.name || USER_DEFAULT_DATA.name,
            birthDate: userMenuFormData.birthDate || USER_DEFAULT_DATA.birthDate,
            email: userMenuFormData.email || USER_DEFAULT_DATA.email,
        });
    }, [userMenuFormData]);
    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <div>
                <div className={classes.controlFields}>
                    <div className={classes.field}>
                        <Field name="name" component={TextControlField} label={t('common:userMenu:profile:name')} />
                    </div>
                    <div className={classes.field}>
                        <Field
                            name="birthDate"
                            component={KeyboardDatePickerControlField}
                            label={t('common:userMenu:profile:birthDate')}
                        />
                    </div>
                    <div className={classes.field}>
                        <Field
                            name="email"
                            validate={emailControlValidator}
                            component={TextControlField}
                            label={t('common:userMenu:profile:email')}
                        />
                    </div>
                    {error && (
                        <div className={classes.field}>
                            <FormErrorMessage error={error} />
                        </div>
                    )}
                </div>
                <div className={classes.phone}>
                    <MobilePhoneControl value={phone} label={t('common:userMenu:profile:phone')} isDisabled={true} />
                </div>
                <div
                    className={classes.reset}
                    onClick={() => {
                        action(makeUserMenuHideAction);
                        window.location.assign(RoutePath.RESET_PASSWORD);
                    }}
                >
                    <div className={classes.icon}>
                        <Icon component={ResetPasswordIcon} size={16} svgSize={16} />
                    </div>
                    <Text typography={TextTypography.PARA_ACCENT}>{t('common:userMenu:profile:reset')}</Text>
                </div>
            </div>
            <Footer className={classes.saveButton}>
                <LargeButton disabled={!!error || !isUIEnabled} type="submit">
                    {t('common:userMenu:profile:saveButton')}
                </LargeButton>
            </Footer>
        </form>
    );
});
