import { OrderStatus } from 'types';

import { ReactComponent as NewIcon } from 'assets/glyphs/new.svg';
import { ReactComponent as AssemblingIcon } from 'assets/glyphs/assembling.svg';
import { ReactComponent as DeliveringIcon } from 'assets/glyphs/delivering.svg';
import { ReactComponent as SuccessIcon } from 'assets/glyphs/success.svg';
import { ReactComponent as WaitingForApprovalIcon } from 'assets/glyphs/waiting-for-confirmation.svg';

export const getOrderStatusIcon = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.NEW:
            return NewIcon;
        case OrderStatus.WAITING_FOR_APPROVAL:
            return WaitingForApprovalIcon;
        case OrderStatus.PREPARING:
            return AssemblingIcon;
        case OrderStatus.DELIVERY:
            return DeliveringIcon;
        case OrderStatus.CLOSED:
            return SuccessIcon;
        default:
            return null;
    }
};
