import React from 'react';

import { useAction, useActionDispatchOnMount } from 'core/store/store';
import { ProfileForm, ProfileFormData } from '../../forms/ProfileForm/ProfileForm';
import { makeUserMenuProfileFetchAction, makeUserMenuProfileSubmitAction } from 'state/userMenu/actions';

export interface ProfileCardProps {
    onClose?: () => void;
    isVendorProfile?: boolean;
}

export const ProfileCard = (props: ProfileCardProps) => {
    const action = useAction();
    const { onClose, isVendorProfile } = props;
    useActionDispatchOnMount(true, makeUserMenuProfileFetchAction);
    return (
        <ProfileForm
            isVendorProfile={isVendorProfile}
            onSubmit={(data: ProfileFormData) => {
                action(makeUserMenuProfileSubmitAction, data);
                onClose();
            }}
        />
    );
};
