import React from 'react';
import i18n from '../../../i18n';
import { Box, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAction } from 'core/store/store';

import { makeCheckoutChangePaymentStatusAction } from 'state/checkout/actions';
import { OnlinePaymentStatus } from 'state/checkout/reducer';

import { Icon } from 'components/Icon/Icon';
import { LargeButton } from 'components/LargeButton/LargeButton';
import { FailurePage } from 'components/FailurePage/FailurePage';
import { Text, TextTypography } from 'components/Text/Text';

import { ReactComponent as FailureIcon } from 'assets/glyphs/failure.svg';
import { ReactComponent as LogoVertical } from 'assets/logo-vertical.svg';

const useStyles = makeStyles(theme => ({
    contents: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
        },
    },
    gridEl: {
        maxWidth: 340,
        margin: 'auto',
    },
}));

export const CheckoutPaymentFailure = () => {
    const action = useAction();
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    return (
        <FailurePage>
            <div className={classes.contents}>
                <Hidden xsDown>
                    <Box m={2} textAlign="center">
                        <LogoVertical />
                    </Box>
                </Hidden>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Icon component={FailureIcon} size={64} svgSize={16} />
                    </Grid>
                    <Grid item xs={12}>
                        <Text typography={TextTypography.DISPLAY_STRONG}>{t('feed:checkout.failure.title')}</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.gridEl}>
                            <LargeButton
                                onClick={() => {
                                    action(makeCheckoutChangePaymentStatusAction, OnlinePaymentStatus.FORM);
                                }}
                            >
                                {t('feed:checkout.failure.okButton')}
                            </LargeButton>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </FailurePage>
    );
};
