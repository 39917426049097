import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { feedAddressCommentSelector, feedAddressSelector } from 'state/feed/selectors';
import {
    checkoutCardStorePromosSelector,
    checkoutCartSelector,
    checkoutCartStoreInfoSelector,
    checkoutIsoCodeSelector,
    checkoutTotalPriceSelector,
} from 'state/checkout/selectors';
import { CheckoutButton } from './CheckoutButton';
import { Text, TextTypography } from 'components/Text/Text';

import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { Icon } from 'components/Icon/Icon';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import { T } from 'components/T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { Currency } from '../../components/Currency/Currency';
import { HorizontalDivider } from '../../components/HorizontalDivider/HorizontalDivider';

export interface CheckoutFinishCartOrdersProps {
    isFullScreen?: boolean;
    onOpenPrevOrderPage?: () => void;
    onClose?: () => void;
}

const useStyles = makeStyles(theme => ({
    header: {
        height: 87,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    orderTextWrapper: {
        alignText: 'center',
        textAlign: 'center',
        flex: 1,
    },
    mainContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'rgba(15, 24, 37, 0.05)',
    },
    containerScroll: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(3),
    },
    icon: {
        display: 'block',
        fill: theme.palette.brandPrimary.main,
        height: theme.spacing(2),
        width: theme.spacing(2),
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
    logoIcon: {
        cursor: 'pointer',
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    iconContainer: {
        cursor: 'pointer',
        borderRadius: theme.spacing(2),
        height: theme.spacing(4),
        padding: theme.spacing(1),
        width: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.lightest,
        },
    },
    cartItem: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        '&:first-child': {
            paddingTop: 0,
        },
    },
    checkout: {
        cursor: 'pointer',
        padding: theme.spacing(2),
    },
    name: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    itemPay: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    itemPayText: {
        marginLeft: 4,
        textTransform: 'lowercase',
    },
    line: {
        margin: theme.spacing(2, 0),
    },
    address: {
        marginBottom: theme.spacing(1),
    },
    promoItem: {
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        backgroundColor: 'rgba(117, 195, 69, 0.15)',
    },
    optionRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    optionRowName: {
        marginRight: 8,
    },
}));

export const CheckoutFinishCartOrders = (props: CheckoutFinishCartOrdersProps) => {
    const { isFullScreen, onClose } = props;

    const address = useSelector(feedAddressSelector);
    const comment = useSelector(feedAddressCommentSelector);
    const storeInfo = useSelector(checkoutCartStoreInfoSelector);
    const cart = useSelector(checkoutCartSelector);
    const isoCode = useSelector(checkoutIsoCodeSelector);
    const promos = useSelector(checkoutCardStorePromosSelector);
    const totalPrice = useSelector(checkoutTotalPriceSelector);

    const { t } = i18n.useTranslation();

    const classes = useStyles({ isFullScreen });

    const activePromoId = cart.selectedPromo?.promo.promoId;

    const selectedPromo = activePromoId ? promos.find(promo => promo.promoId === activePromoId) : null;

    const promoText =
        cart.selectedPromo?.promoApplied && selectedPromo
            ? selectedPromo.description
            : cart.selectedPromo?.promoNotification;

    return (
        <ErrorBoundary>
            <Box className={classes.mainContainer}>
                <div className={classes.header}>
                    <div className={classes.iconContainer} onClick={onClose}>
                        <Icon component={BackIcon} size={16} svgSize={16} className={classes.icon} />
                    </div>
                    <div className={classes.orderTextWrapper}>
                        <Box textAlign={'center'}>
                            <Text typography={TextTypography.LEAD_STRONG}>{t('feed:checkout.orderDetails')}</Text>
                        </Box>
                    </div>
                    <div className={classes.iconContainer} />
                </div>

                <div className={classes.containerScroll}>
                    {cart.orderedProducts?.map((product, i) => (
                        <>
                            <div key={i} className={classes.cartItem}>
                                <div>
                                    <Text typography={TextTypography.BODY_ACCENT}>{product.name}</Text>
                                </div>
                                <div>
                                    <Text typography={TextTypography.BODY_ACCENT}>
                                        <Currency isoCode={isoCode}>{product.totalPrice}</Currency>
                                    </Text>
                                </div>
                            </div>
                            <div>
                                {' '}
                                {product?.orderedOptions.map(option => (
                                    <div key={option.optionId} className={classes.optionRow}>
                                        <Text className={classes.optionRowName} typography={TextTypography.BODY}>
                                            {option.name}
                                        </Text>
                                        <Text typography={TextTypography.BODY}>
                                            <Currency isoCode={isoCode}>{option.price}</Currency>
                                        </Text>
                                    </div>
                                ))}
                            </div>
                        </>
                    ))}

                    {promoText?.length > 0 && (
                        <>
                            <div className={classes.line}>
                                <HorizontalDivider />
                            </div>
                            <div className={classes.promoItem}>{promoText}</div>
                        </>
                    )}
                    <div className={classes.line}>
                        <HorizontalDivider />
                    </div>
                    <div className={classes.cartItem}>
                        <div className={classes.itemPay}>
                            <div>
                                <Text typography={TextTypography.BODY}>
                                    {t('feed:checkout.payment.titleWithSpace')}
                                </Text>
                            </div>
                            <div className={classes.itemPayText}>
                                <Text typography={TextTypography.BODY}>
                                    <T enum={TranslatableEnum.PAYMENT_TYPE}>{cart?.paymentInfo?.paymentType}</T>
                                </Text>
                            </div>
                        </div>
                        <div>
                            <Text typography={TextTypography.BODY_ACCENT}>
                                <Currency isoCode={isoCode}>{totalPrice}</Currency>
                            </Text>
                        </div>
                    </div>
                    <div className={classes.line}>
                        <HorizontalDivider />
                    </div>
                    <div className={classes.cartItem}>
                        <div>
                            <Text typography={TextTypography.BODY}>{t('feed:checkout.shipping.title')}</Text>
                        </div>
                        <div>
                            <Text typography={TextTypography.BODY_ACCENT}>
                                <T enum={TranslatableEnum.DELIVERY_TYPE}>{cart?.deliveryInfo?.deliveryType}</T>
                            </Text>
                        </div>
                    </div>
                    <div className={classes.line}>
                        <HorizontalDivider />
                    </div>
                    <div className={classes.cartItem}>
                        <div>
                            <div className={classes.address}>
                                <Text typography={TextTypography.BODY}>{t('feed:checkout.shipping.address')}</Text>
                            </div>
                            <div>
                                <Text typography={TextTypography.BODY_ACCENT}>{address}</Text>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cartItem}>
                        <div>
                            <div className={classes.address}>
                                <Text typography={TextTypography.BODY}>{t('feed:checkout.shipping.comment')}</Text>
                            </div>
                            <div>
                                <Text typography={TextTypography.BODY_ACCENT}>{comment}</Text>
                            </div>
                        </div>
                    </div>
                </div>

                <Box m={2}>
                    <Text typography={TextTypography.BODY}>{t('feed:checkout.finish')} </Text>
                </Box>

                <div>
                    <div
                        className={classes.checkout}
                        style={{ backgroundColor: !storeInfo?.storeDeliveries && 'rgba(15, 24, 37, 0.05)' }}
                    >
                        <CheckoutButton />
                    </div>
                </div>
                <CustomerMenu navigationPath={CustomerMenuSelectedItem.CHECKOUT} />
            </Box>
        </ErrorBoundary>
    );
};
