import React, { useState } from 'react';
import i18n from '../../../i18n';
import { makeStyles } from '@material-ui/core/styles';

import { StageItem } from '../Stage/StageItem';
import { Stage } from '../Stage/Stage';
import { Text, TextTypography } from '../Text/Text';
import { OrdersTable } from './OrdersTable';
import { OrdersStatusHistory } from './OrdersStatusHistory';

import { OrderChangeStatusIntent, UserRole } from 'types';
import { OrderResponse } from 'state/order/types';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    header: {
        flexGrow: 0,
        flexBasis: 'auto',
    },
    orderDetails: {
        flex: 1,
    },
});

enum OrdersDialogTab {
    STATUS,
    DETAILS,
}

export interface OrderCardProps {
    order: OrderResponse;
    userRole: UserRole;
    onIntent: (intent: OrderChangeStatusIntent) => void;
    isPending: boolean;
}

export const OrderCard = (props: OrderCardProps) => {
    const { t } = i18n.useTranslation();
    const { order, userRole, onIntent, isPending } = props;
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState<OrdersDialogTab>(OrdersDialogTab.STATUS);
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Stage value={activeTab} onChange={setActiveTab}>
                    <StageItem
                        label={
                            <Text component="span" typography={TextTypography.BODY_HEADING}>
                                {t('orders:dialog.status')}
                            </Text>
                        }
                        onClick={() => setActiveTab(OrdersDialogTab.STATUS)}
                    />
                    <StageItem
                        label={
                            <Text component="span" typography={TextTypography.BODY_HEADING}>
                                {t('orders:dialog.details')}
                            </Text>
                        }
                        onClick={() => setActiveTab(OrdersDialogTab.DETAILS)}
                    />
                </Stage>
            </div>
            <div className={classes.orderDetails}>
                {activeTab === OrdersDialogTab.STATUS && order && (
                    <OrdersStatusHistory
                        id={order.orderId}
                        statusHistory={order.orderStatusHistory}
                        userRole={userRole}
                        onIntent={onIntent}
                        isPending={isPending}
                    />
                )}
                {activeTab === OrdersDialogTab.DETAILS && order && <OrdersTable orderResponse={order} />}
            </div>
        </div>
    );
};
