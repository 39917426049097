import React, { useCallback, useState } from 'react';
import i18n from '../../../i18n';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { BackButton } from 'components/BackButton/BackButton';
import { GeoMap } from 'components/GeoMap/GeoMap';
import { LargeButton } from 'components/LargeButton/LargeButton';
import { SelectControl } from 'components/SelectControl/SelectControl';

import { ReactComponent as LocationImage } from 'assets/images/location.svg';

import { MapControlCityView } from './MapControlCityView';
import { MapControlStreetAddressView } from './MapControlStreetAddressView';
import { GoogleMapsLoadedObserver } from '../GoogleMapsLoadedObserver/GoogleMapsLoadedObserver';

export interface MapControlMapViewProps {
    hideCloseButton: boolean;
    isGeolocationPending: boolean;
    buttonText: string;
    headerText: string;
    lat: number;
    lon: number;
    city: string;
    setCity: (city: string) => void;
    streetAddress: string;
    setStreetAddress: (streetAddress: string, skipSetCoordinates?: boolean) => void;
    forbidClosingWithEmptyAddress: boolean;
    onClose: () => void;
    onCenterChanged: (lat: number, lon: number) => void;
    onOk: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    mapContainer: {
        flex: 1,
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flex: 0,
            flexBasis: 'auto',
        },
    },
    formCenterer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        maxWidth: 360,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'unset',
            padding: theme.spacing(2),
        },
    },
    backButton: {
        position: 'fixed',
        top: 16,
        left: 16,
        width: 32,
        height: 32,
        zIndex: 1,
    },
}));

export const MapControlMapView = (props: MapControlMapViewProps) => {
    const { city, setCity, setStreetAddress } = props;

    const { t } = i18n.useTranslation();
    const classes = useStyles();
    const isOkButtonDisabled = props.forbidClosingWithEmptyAddress && !props.streetAddress;

    const [isCityDialogOpen, setIsCityDialogOpen] = useState(false);
    const openCityDialog = useCallback(() => setIsCityDialogOpen(true), [setIsCityDialogOpen]);
    const closeCityDialog = useCallback(() => setIsCityDialogOpen(false), [setIsCityDialogOpen]);

    const [isStreetAddressDialogOpen, setIsStreetAddressDialogOpen] = useState(false);
    const openStreetAddressDialog = useCallback(() => {
        if (city) {
            setIsStreetAddressDialogOpen(true);
        }
    }, [city, setIsStreetAddressDialogOpen]);
    const closeStreetAddressDialog = useCallback(() => setIsStreetAddressDialogOpen(false), [
        setIsStreetAddressDialogOpen,
    ]);

    const onSelectCity = useCallback(
        (newCity: string) => {
            setIsCityDialogOpen(false);
            return setCity(newCity);
        },
        [setCity, setIsCityDialogOpen],
    );

    const onSelectStreetAddress = useCallback(
        (value: string, isStreetAddress: boolean) => {
            if (isStreetAddress) {
                setIsStreetAddressDialogOpen(false);
            }
            return setStreetAddress(value, !isStreetAddress);
        },
        [setStreetAddress, setIsStreetAddressDialogOpen],
    );

    return (
        <div className={classes.container}>
            {!props.hideCloseButton && (
                <div className={classes.backButton}>
                    <BackButton onClick={props.onClose} />
                </div>
            )}
            <div className={classes.mapContainer}>
                <GoogleMapsLoadedObserver>
                    <GeoMap lat={props.lat} lon={props.lon} onCenterChanged={props.onCenterChanged} />
                </GoogleMapsLoadedObserver>
            </div>
            <div className={classes.dialog}>
                <div className={classes.formCenterer}>
                    <div className={classes.form}>
                        <Grid container spacing={2}>
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <LocationImage />
                                </Grid>
                            </Hidden>
                            {props.headerText && (
                                <Grid item xs={12}>
                                    {props.headerText}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <SelectControl
                                    isDisabled={props.isGeolocationPending}
                                    isReadOnly={true}
                                    label={t('common:addressControl.city')}
                                    value={city}
                                    isOpen={isCityDialogOpen}
                                    onOpen={openCityDialog}
                                    onClose={closeCityDialog}
                                >
                                    <MapControlCityView onSelect={onSelectCity} value={city} />
                                </SelectControl>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectControl
                                    isDisabled={props.isGeolocationPending || !city}
                                    label={t('common:addressControl.streetAddress')}
                                    value={props.streetAddress}
                                    isReadOnly={true}
                                    isOpen={isStreetAddressDialogOpen}
                                    onOpen={openStreetAddressDialog}
                                    onClose={closeStreetAddressDialog}
                                >
                                    <MapControlStreetAddressView
                                        onSelect={onSelectStreetAddress}
                                        value={props.streetAddress}
                                        city={city}
                                        lat={props.lat}
                                        lon={props.lon}
                                    />
                                </SelectControl>
                            </Grid>
                            <Grid item xs={12}>
                                <LargeButton disabled={isOkButtonDisabled} onClick={props.onOk}>
                                    {props.buttonText}
                                </LargeButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
};
