import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        marginTop: theme.spacing(10),
        textAlign: 'center',
    },
}));

export const CircularProgressPage: FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <CircularProgress />
        </div>
    );
};
