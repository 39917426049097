import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { OrderListItem } from './OrderListItem';

import { OrderListResponse, OrderListResponseItem } from 'state/order/types';
import { SortingDirection } from 'types';

export interface OrderListSorting {
    field: keyof OrderListResponseItem;
    direction: SortingDirection;
}

export interface OrderListProps {
    orders: OrderListResponse;
    onClick: (storeId: string, orderId: string) => void;
    // Optional
    sorting?: OrderListSorting;
    showRating?: boolean;
    onRate?: (orderId: string, rating: number) => void;
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(-1),
    },
    container: {
        alignContent: 'stretch',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 auto',
        maxWidth: 800,
    },
    item: {
        flex: 1,
        margin: theme.spacing(1, 1),
        minWidth: 320,
        width: '100%',
        [theme.breakpoints.down(350)]: {
            margin: theme.spacing(1, 0),
            minWidth: 'auto',
        },
    },
}));
type OrderSortingComparator = (a: OrderListResponseItem, b: OrderListResponseItem) => number;

const orderSortingComparators: Partial<Record<keyof OrderListResponseItem, OrderSortingComparator>> = {
    orderCreationDateTime: (a: OrderListResponseItem, b: OrderListResponseItem) => {
        return new Date(a.orderCreationDateTime).getTime() - new Date(b.orderCreationDateTime).getTime();
    },
    orderStatus: (a: OrderListResponseItem, b: OrderListResponseItem) => {
        return a.orderStatus.localeCompare(b.orderStatus);
    },
};

const getComparator = (field: keyof OrderListResponseItem): OrderSortingComparator => {
    return (
        orderSortingComparators[field] ||
        ((a: OrderListResponseItem, b: OrderListResponseItem) => Number(a[field]) - Number(b[field]))
    );
};

export const OrderList = (props: OrderListProps) => {
    const classes = useStyles();
    const { orders, sorting, showRating, onRate } = props;
    const sortedOrders = useMemo(() => {
        if (sorting?.field) {
            const comparator = getComparator(sorting.field);
            return orders.sort((a, b) => sorting.direction * comparator(a, b));
        }
        return orders;
    }, [orders, sorting]);

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                {sortedOrders.map(order => {
                    return (
                        <div key={order.orderId} className={classes.item}>
                            <OrderListItem
                                order={order}
                                showRating={showRating}
                                onClick={() => {
                                    props.onClick(order.storeId, order.orderId);
                                }}
                                onRate={onRate}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
