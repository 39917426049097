import React from 'react';
import i18n from '../../../i18n';

import { MapControlAutocomplete } from './MapControlAutocomplete';

import { Endpoint } from 'Endpoint';

export interface MapControlStreetAddressViewProps {
    lat: number;
    lon: number;
    value: string;
    onSelect: (value: string, isStreetAddress: boolean) => void;
    city: string;
}

export const MapControlStreetAddressView = (props: MapControlStreetAddressViewProps) => {
    const { t } = i18n.useTranslation();
    return (
        <MapControlAutocomplete
            label={t('common:addressControl.streetAddress')}
            value={props.value}
            onSelect={props.onSelect}
            location={{ lat: props.lat, lon: props.lon }}
            radius={25e3}
            endpoint={Endpoint.ADDRESS_AUTOCOMPLETE}
            filterBy={props.city}
            displayValidation={true}
        />
    );
};
