import React, { FC } from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as CallIcon } from 'assets/glyphs/call.svg';

export interface CallButtonProps {
    phoneNumber: string;
}

export const useIconButtonStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.brandPrimary.lightest,
        borderRadius: theme.spacing(1.25),
        height: theme.spacing(6),
        width: theme.spacing(8),
    },
}));

export const CallButton: FC<CallButtonProps> = props => {
    const iconButtonClasses = useIconButtonStyles();
    const href = `tel:${props.phoneNumber}`;
    return (
        <IconButton classes={iconButtonClasses} component="a" href={href}>
            <SvgIcon component={CallIcon} viewBox="0 0 20 20" />
        </IconButton>
    );
};
