import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';
import { ReactComponent as ForwardIcon } from 'assets/icons/main-16/arrow-right--16.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/social/facebook--24.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/social/insta--24.svg';
import { RoutePath } from 'RoutePath';
import { Text, TextTypography } from 'components/Text/Text';
import i18n from '../../../i18n';
import { WhatsappAskQuestion } from 'components/WhatsappAskQuestion/WhatsappAskQuestion';
import { appConfig } from 'config/app';
import browserCookies from 'browser-cookies';
import { SupportedCountryCodes } from 'types';

export interface FeedFooterNavigationDef {
    path: RoutePath;
    label: string;
}

const feedFooterNavItems: FeedFooterNavigationDef[] = [
    {
        path: RoutePath.ABOUT,
        label: 'common:footer.aboutUs',
    },
    {
        path: RoutePath.SELLERS,
        label: 'common:footer.forSellers',
    },
    {
        path: RoutePath.BUYERS,
        label: 'common:footer.forCustomers',
    },
    {
        path: RoutePath.DELIVERY,
        label: 'common:footer.deliveryInfo',
    },
    {
        path: RoutePath.PAYMENTS,
        label: 'common:footer.paymentInfo',
    },
    {
        path: RoutePath.BLOG,
        label: 'common:footer.blog',
    },
    {
        path: RoutePath.CONTACTS,
        label: 'common:footer.contacts',
    },
];

const useStyles = makeStyles(theme => ({
    footerContainer: {
        borderTop: `1px solid ${theme.palette.brandPrimary.lightest}`,
    },
    footerNavContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        paddingBottom: 0,
    },
    footerNavElement: {
        width: '50%',
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        '&:nth-child(n)': {
            borderBottom: `1px solid ${theme.palette.brandPrimary.lightest}`,
        },
    },
    footerNavElementBody: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0px ${theme.spacing(3)}px`,
    },
    footerNavElementIcon: {
        fontSize: 12,
    },
    footerInfoContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        paddingBottom: 0,
        backgroundColor: 'rgba(15, 24, 37, 0.025)', //0.5 + opacity = 0.5
        borderTop: `1px solid ${theme.palette.brandPrimary.lightest}`,
        marginTop: '-1px',
        padding: '10px 24px 0px 24px',
    },

    footerInfoElement: {
        flexBasis: '50%',
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.lightest}`,
    },
    plainPhone: {
        flex: 1,
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
    },
    plainText: {
        flex: 1,
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
    },
    copyrightText: {
        fontWeight: 400,
    },
    whatsApp: {
        padding: 0,
        '& svg': {
            fontSize: 32,
            '& path': {
                fill: `${theme.palette.fixedPrimary.primary}`,
            },
        },
    },
    socials: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-end',
        '& div': {
            display: 'flex',
        },
        '& a': {
            marginLeft: theme.spacing(3),
            '& svg': {
                fontSize: 32,
                '& path': {
                    fill: `${theme.palette.fixedPrimary.primary}`,
                },
            },
        },
    },
    link: {
        color: 'inherit',
    },
}));

const isServer = typeof window === 'undefined';

export const FooterMenu = () => {
    const classes = useStyles();
    const {
        t,
        i18n: { language },
    } = i18n.useTranslation();
    const isoCode = !isServer ? browserCookies?.get('country') : SupportedCountryCodes.RUS;
    const notTrimmedMarketplacePhoneNumber = isoCode !== SupportedCountryCodes.RUS
        ? appConfig.notTrimmedMarketplacePhoneNumber
        : appConfig.notTrimmedMarketplacePhoneNumberRu;
    const marketplacePhoneNumber = isoCode !== SupportedCountryCodes.RUS
        ? appConfig.marketplacePhoneNumber
        : appConfig.marketplacePhoneNumberRu;

    return (
        <>
            <div className={classes.footerContainer}>
                <div className={classes.footerNavContainer}>
                    {feedFooterNavItems
                        .filter(el => isoCode === SupportedCountryCodes.RUS || el.path !== RoutePath.DELIVERY)
                        .filter(el => (language === 'ru' ? true : el.path !== RoutePath.BLOG))
                        .map((navigationItem, i) => (
                            <div key={i} className={classes.footerNavElement}>
                                <a
                                    className={classes.footerNavElementBody}
                                    href={navigationItem.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Text typography={TextTypography.BODY_ACCENT} shouldCollapseText itemProp="name">
                                        {t(navigationItem.label)}
                                    </Text>
                                    <SvgIcon
                                        className={classes.footerNavElementIcon}
                                        component={ForwardIcon}
                                        viewBox="0 0 12 12"
                                    />
                                </a>
                            </div>
                        ))}
                </div>
            </div>
            <div className={classes.footerInfoContainer}>
                <div className={classes.footerInfoElement}>
                    <WhatsappAskQuestion className={classes.whatsApp} marketplacePhoneNumber={marketplacePhoneNumber} />
                </div>
                <div className={classes.footerInfoElement}>
                    {isoCode !== SupportedCountryCodes.RUS && (
                        <div className={classes.socials}>
                            <div>
                                <a
                                    href="https://www.facebook.com/profile.php?id=100086871993558"
                                    target="_blank"
                                    itemProp="url"
                                    rel="noopener noreferrer"
                                >
                                    <div>
                                        <SvgIcon component={FacebookIcon} viewBox="0 0 32 32" />
                                    </div>
                                </a>
                                <a
                                    href="https://instagram.com/any.market.uk?igshid=YmMyMTA2M2Y="
                                    target="_blank"
                                    itemProp="url"
                                    rel="noopener noreferrer"
                                >
                                    <div>
                                        <SvgIcon component={InstagramIcon} viewBox="0 0 32 32" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.footerInfoElement}>
                    <div className={classes.plainPhone}>
                        <Text typography={TextTypography.BODY_ACCENT}>
                            {isoCode !== SupportedCountryCodes.RUS &&<a href={`tel:${marketplacePhoneNumber}`} className={classes.link}>
                                    {notTrimmedMarketplacePhoneNumber}
                                </a>
                            }
                        </Text>
                    </div>
                </div>
                <div className={classes.footerInfoElement}>
                    <a className={classes.plainText} href={RoutePath.LEGAL}>
                        <Text typography={TextTypography.BODY_ACCENT}>{t('common:footer.documents')}</Text>
                    </a>
                </div>
            </div>
        </>
    );
};
