import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from '../Icon/Icon';
import { T } from '../T/T';
import { Text, TextTypography } from '../Text/Text';

import { getOrderStatusIcon } from './utils';

import { OrderStatus } from 'types';

import { ReactComponent as SuccessIcon } from 'assets/glyphs/success.svg';
import { TranslatableEnum } from '../T/GetTranslationForEnum';

export interface OrdersStatusHistoryRowProps {
    complete: boolean;
    status: OrderStatus;
    isCancelled: boolean;
}

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        padding: theme.spacing(2),
    },
    rowIcon: {
        flexBasis: theme.spacing(6),
        marginRight: theme.spacing(1),
        textAlign: 'center',
    },
    rowStatus: {
        flex: 1,
    },
    rowStatusText: {
        lineHeight: '32px',
    },
    rowDetails: {
        flexGrow: 0,
        flexBasis: 'auto',
        textAlign: 'right',
        lineHeight: '32px',
        whiteSpace: 'nowrap',
    },
}));

const getTranslateEnum = (isCancelled: boolean, isComplete: boolean) => {
    if (isCancelled) {
        return TranslatableEnum.ORDER_STATUS_CANCELLED;
    }
    if (isComplete) {
        return TranslatableEnum.ORDER_STATUS_COMPLETE;
    }
    return TranslatableEnum.ORDER_STATUS;
};

export const OrdersStatusHistoryRow: FC<OrdersStatusHistoryRowProps> = props => {
    const classes = useStyles();
    const StatusIcon = props.complete ? SuccessIcon : getOrderStatusIcon(props.status);
    const en = getTranslateEnum(props.isCancelled, props.complete);
    return (
        <div className={classes.row}>
            <div className={classes.rowIcon}>
                {StatusIcon && <Icon component={StatusIcon} size={32} svgSize={16} />}
            </div>
            <div className={classes.rowStatus}>
                <Text className={classes.rowStatusText} typography={TextTypography.LEAD_STRONG}>
                    <T enum={en}>{props.status}</T>
                </Text>
            </div>
            <div className={classes.rowDetails}>{props.children}</div>
        </div>
    );
};
