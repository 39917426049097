import React, { FC, useEffect, useRef } from 'react';
import useDigitInput from 'react-digit-input';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import i18n from '../../../i18n';

import { range } from 'core/utils/utils';

export interface CodeControlProps {
    // Optional:
    disabled?: boolean;
    error?: string;
    name?: string;
    value?: string;
    onChange?: any;
}

const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: theme.palette.brandPrimary.lightest,
        borderRadius: theme.spacing(0.75),
        borderWidth: 0,
        borderColor: 'transparent',
        width: theme.spacing(5),
        height: theme.spacing(6),
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.333,
        textAlign: 'center',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
}));

const useErrorStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
}));

export const CodeControl: FC<CodeControlProps> = props => {
    const N = 6;
    const classes = useStyles();
    const errorClasses = useErrorStyles();
    const { error, value, onChange, disabled } = props;
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange,
    });
    const ref = useRef<HTMLDivElement>();
    useEffect(() => {
        if (value === '') {
            const input = ref.current.querySelector('input');
            if (input) {
                setTimeout(() => input.focus());
            }
        }
    }, [value]);
    return (
        <div ref={r => (ref.current = r)}>
            {range(0, N - 1).map(i => (
                <input
                    key={i}
                    disabled={disabled}
                    className={classes.input}
                    inputMode="decimal"
                    autoFocus={i === 0}
                    {...digits[i]}
                />
            ))}
            {error && (
                <FormHelperText classes={errorClasses} error={true}>
                    {error}
                </FormHelperText>
            )}
        </div>
    );
};

export interface CodeControlFieldOwnProps {
    // Optional:
    disabled?: boolean;
    error?: string;
}

export type CodeControlFieldProps = CodeControlFieldOwnProps & WrappedFieldProps;

export const CodeControlField: FC<CodeControlFieldProps> = props => {
    const { t } = i18n.useTranslation();
    const {
        input,
        meta: { invalid, error },
        ...rest
    } = props;
    return <CodeControl error={invalid ? t(error) : undefined} {...input} {...rest} />;
};
